import React, { useEffect, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { useReactOidc } from '@axa-fr/react-oidc-context';
import './styles.scss';
import * as Constants from '../../utils/constants'
import { config } from '../../utils/config';
import { setAuthHeader } from '../../api/api'
import { getCustomerUserProductFeature } from '../../api/customer';
import { MenuBar } from "@metaforcelabs/metaforce-core";
import { uploadEventLog } from '../../api/audit';

const Oidc = () => {
    const navigate = useNavigate()
    const { oidcUser } = useReactOidc();

    useEffect(() => {
        if (oidcUser) {
            setAuthHeader(oidcUser.access_token);
            validateLicense();
        }
    }, [oidcUser]);

    const validateLicense = async () => {
        const customerUserAccessRoleDto = await getCustomerUserProductFeature();
        const hasAccess = customerUserAccessRoleDto?.productFeatureAccessSelections?.find(x => x.productId === Constants.adminProductId) || false;
        if (hasAccess) {
            if (window.location.pathname === '/') {
                await uploadEventLog({
                    type: 1,
                });
            }
            navigate(window.location.pathname); // redirects to root to prevent site from hanging on a blank screen
        }
        else {
            navigate('/missing-license');
        }
    }

    return (
            <div className="relative min-h-screen flex flex-col bg-white">
                <MenuBar
                    navigation={[]}
                    homeButtonLink={config.centerpointUiBaseUrl}
                    helpLink={config.docsUrl}
                />

                <main className="main-container bg-gray-50 flex-1 z-0 focus:outline-none">
                    <div className="max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
                        <div className="flex flex-col pb-10">
                            <div className="max-w-screen-3xl">
                                <p className="text-2xl font-bold mt-6">Loading...</p>
                            </div>
                        </div>
                    </div>
                </main>
        </div>
    )
}


export default Oidc;