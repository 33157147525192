import { Transition } from '@headlessui/react'
import { FormTextArea } from '../../../components/Form/FormTextArea';
import RichTextEditor from '../../../components/RichTextEditor';
import { XIcon } from "@heroicons/react/outline";
import * as _ from "lodash"

export default function TextValueComponent({ currentTextValue, onDescriptionChange, onTextChange, disabled, stylesRef, fontsRef, errors, onClose }) {
    return (
        /* Use the `open` state to conditionally change the direction of an icon. */
        <Transition
            show={currentTextValue.open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
        >
            <div className='border rounded-md px-4 py-5 flex flex-col gap-y-5'>
                <div className="flex items-start">
                    <p className="flex-1 text-md font-medium text-gray-900">{currentTextValue.languageName}</p>
                    {/* <div className="ml-4 flex flex-shrink-0">
                        <button
                            type="button"
                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => {
                                onClose(currentTextValue.value.languageId);
                            }}
                        >
                            <span className="sr-only">Close</span>
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div> */}
                </div>

                <FormTextArea
                    name="description"
                    label={"Description"}
                    rows={3}
                    disabled={disabled}
                    value={currentTextValue.value.description}
                    onChange={(e) => {
                        onDescriptionChange(currentTextValue, e.target.value);
                    }}
                />

                <div className="w-full flex flex-col">
                    <RichTextEditor
                        languageId={currentTextValue.value.languageId}
                        disabled={disabled}
                        stylesRef={stylesRef}
                        fontsRef={fontsRef}
                        text={currentTextValue.value.text}
                        setText={(text) => {
                            onTextChange(currentTextValue, text);
                        }}
                    />
                    {errors && errors[currentTextValue.value.languageId] &&
                        <div className="text-sm text-red-600 font-medium">{errors[currentTextValue.value.languageId]}</div>
                    }

                </div>
            </div>
        </Transition>
    )
}
