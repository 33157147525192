import React, { useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './day-picker.css';
import DateTimeParser from '../../utils/DateTimeParser';
import styles from './DateField.module.scss';
import { useClassNames } from '@metaforcelabs/metaforce-core';
import { CalendarIcon } from '@heroicons/react/outline';

import en from 'date-fns/locale/en-US';
import da from 'date-fns/locale/da';
import fi from 'date-fns/locale/fi';
import nb from 'date-fns/locale/nb';
import sv from 'date-fns/locale/sv';

const DateField = (props) => {
  const currentYear = new Date().getFullYear();
  const fromYear = currentYear - 100;
  const toYear = currentYear + 150;
  const dayPickerWrapperRef = useRef(null);
  const dayPickerRef = useRef(null);
  const [isShowingDayPicker, setIsShowingDayPicker] = useState(false);
  const [selectedDay, setSelectedDay] = useState(
    props.value ? DateTimeParser.toDateObject(props.value) : undefined
  );
  const { classNames } = useClassNames();

  const handleLocale = () => {
    if (props.cultureCode === 'da') {
      return da;
    } else if (props.cultureCode === 'fi') {
      return fi;
    } else if (props.cultureCode === 'nb') {
      return nb;
    } else if (props.cultureCode === 'sv') {
      return sv;
    } else {
      return en;
    }
  }

  const handleYearMonthChange = (date) => {
    const dateValueString = DateTimeParser.toLocaleDateString(date, props.cultureCode);

    setSelectedDay(date);

    if (props.onChange) {
      const handleChangeEvent = {
        target: {
          id: props.name,
          name: props.name,
          value: dateValueString
        }
      };

      props.onChange(dateValueString, handleChangeEvent);
    }
  };

  const handleOnDayClick = (date) => {
    const dateValueString = date === '' ? date : DateTimeParser.toLocaleDateString(date, props.cultureCode);

    setSelectedDay(date === '' ? undefined : date);
    setIsShowingDayPicker(false);

    if (props.onChange) {
      const handleChangeEvent = {
        target: {
          id: props.name,
          name: props.name,
          value: dateValueString
        }
      };

      props.onChange(dateValueString, handleChangeEvent);
    }
  };

  const handleClick = (e) => {
    if (props.disabled) {
      return;
    }
    if (dayPickerWrapperRef.current && dayPickerWrapperRef.current.contains(e.target)) {
      if (!(dayPickerRef.current && dayPickerRef.current.contains(e.target))) {
        setIsShowingDayPicker((prev) => !prev);
      }
    } else {
      setIsShowingDayPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleClick);

    return () => {
      document.removeEventListener('mouseup', handleClick);
    };
  });

  useEffect(() => {
    if (props.value) {
      setSelectedDay(DateTimeParser.toDateObject(props.value));
    }
  }, [props.value]);


  const footer = (
    <div className='grid grid-cols-2'>
      <div className='col-span-1'>
        <button
          type="button"
          className='text-brand-pink hover:text-white hover:bg-brand-pink-hover text-md font-medium rounded px-2 py-1'
          disabled={props.disabled}
          onClick={() => handleOnDayClick('')}
        >
          Clear
        </button>
      </div>

      <div className='col-span-1 w-full flex justify-end '>
        <button
          className='text-brand-pink hover:text-white hover:bg-brand-pink-hover text-md font-medium rounded px-2 py-1'
          disabled={props.disabled}
          onClick={() => handleOnDayClick(new Date())}
        >
          Today
        </button>
      </div>

    </div>
  )

  return (
    <>
      <div data-cy={props.dataCy} ref={dayPickerWrapperRef} className={styles.wrapper}>
        <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
          {props.label}
          {props.required === true && <span className="text-red-400"> *</span>}
        </label>
        <div className="relative mt-1">
          <input
            type="text"
            id={props.name}
            name={props.name}
            value={props.value}
            className={classNames(
              (props.error ? 'border-red-300 ' : 'border-gray-300 ') + 'shadow-sm block w-full sm:text-sm  rounded-md focus:ring-brand-pink focus:border-brand-pink pr-10 ',
              props.disabled && styles.disabled
            )}
            disabled={props.disabled}
            readOnly
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
        <div className="mt-1 text-sm text-red-600 font-medium">{props.error && <div>{props.error}</div>}</div>

        {isShowingDayPicker && !props.disabled && (
          <div ref={dayPickerRef} className={styles.dayPicker}>
            <DayPicker
              month={selectedDay}
              selected={selectedDay}
              onMonthChange={handleYearMonthChange}
              onDayClick={handleOnDayClick}
              locale={handleLocale()}
              modifiersClassNames={{
                selected: styles.dayPickerSelected,
                today: styles.dayPickerToday,
              }}
              fromYear={fromYear}
              toYear={toYear}
              captionLayout="dropdown"
              footer={footer}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DateField;
