import { PageHeader, useClassNames, useToastAction } from "@metaforcelabs/metaforce-core"
import { useState, useEffect, useContext } from "react";
import { createFont, deleteFont, getFonts, } from "../../../api/textlibrary";
import { OidcRoutesContext } from "../../../contexts";
import { useTableSort } from "../../../hooks/useTableSort";
import * as Constants from "../../../utils/constants";
import DateTimeParser from "../../../utils/DateTimeParser";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import toast from "react-hot-toast";
import * as _ from "lodash";
import DeleteDialog from "../DeleteDialog";
import EditFontDialog from "./editFontDialog";
import { MenuListCtxMenu } from "./menuListCtxMenu";
import { useSearchParams } from 'react-router-dom';

export default function FontList() {
    const { userProfile } = useContext(OidcRoutesContext);
    const [fonts, setFonts] = useState([]);
    let [searchParams, setSearchParams] = useSearchParams();

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [fontToEdit, setFontToEdit] = useState({});

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [fontToDelete, setFontToDelete] = useState();

    const { classNames } = useClassNames();
    const loadAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const res = await getFonts();
            setFonts(res);
        }, "Failed to load data");
    }

    useEffect(() => {
        if (searchParams.has('new')) {
            setEditDialogOpen(true);
        }
    }, [searchParams]);

    useEffect(() => {
        loadData();
    }, []);

    const tableSort = useTableSort(fonts, Constants.fontSortProperty.name, Constants.sortOrder.asc);

    const handleEdit = async (confirm) => {
        if (confirm) {
            await loadData();
        }
        setEditDialogOpen(false);
        setFontToEdit(null);
        setSearchParams({});
    }

    const handleDeleteConfirm = async (confirm) => {
        try {
            if (confirm && fontToDelete) {
                await deleteFont(fontToDelete);

                await loadData();
            }
        } catch (err) {
            if (err.response?.status === 400 && err.response?.data?.Message.startsWith("TextLibraryErrors:")) {
                const message = err.response?.data?.Message.replace("TextLibraryErrors:", "");
                toast.error(message, {
                    duration: Infinity,
                });
            } else {
                toast.error("Failed to delete font");
            }
        } finally {
            setDeleteDialogOpen(false);
            setFontToDelete(null);
        }
    }

    return (
        <>
            <div className="flex flex-row justify-between items-end">
                <PageHeader title="Fonts" optionalSideElement={<button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={() => {
                        setEditDialogOpen(true);
                        setFontToEdit({ name: '' });
                    }}
                >
                    Add Font
                </button>} />
            </div>

            {/* <div className="mt-3 mb-5 flex justify-end gap-x-3">
                <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={() => {
                        setEditDialogOpen(true);
                        setFontToEdit({ name: '' });
                    }}
                >
                    Add Font
                </button>
            </div> */}

            <div className="align-middle inline-block min-w-full shadow-sm sm:rounded-lg relative mt-5" >
                <div className="border border-gray-200 ">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.fontSortProperty.name)
                                        }}
                                    >
                                        Name {tableSort.getSortIcon(Constants.fontSortProperty.name)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.fontSortProperty.createdDate)
                                        }}
                                    >
                                        Created {tableSort.getSortIcon(Constants.fontSortProperty.createdDate)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.fontSortProperty.createdByName)
                                        }}
                                    >
                                        Created By {tableSort.getSortIcon(Constants.fontSortProperty.createdByName)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.fontSortProperty.updatedDate)
                                        }}
                                    >
                                        Updated {tableSort.getSortIcon(Constants.fontSortProperty.updatedDate)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.fontSortProperty.updatedByName)
                                        }}
                                    >
                                        Updated By {tableSort.getSortIcon(Constants.fontSortProperty.updatedByName)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 px-6 py-4 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    <span className="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {tableSort?.tableData?.map((font, index) => (
                                <tr key={font.id} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap font-medium text-sm text-indigo-600 overflow-ellipsis overflow-hidden max-w-120 lg:max-w-150 xl:max-w-250">
                                        <button
                                            type="button"
                                            className={"cursor-pointer hover:underline"}
                                            onClick={() => {
                                                setEditDialogOpen(true);
                                                setFontToEdit(font);
                                            }}>
                                            {font.name}
                                        </button>
                                    </td>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{DateTimeParser.toLocaleDateTimeString(font.createdDate, userProfile.languageCode)}</td>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{font.createdByName}</td>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{DateTimeParser.toLocaleDateTimeString(font.updatedDate, userProfile.languageCode)}</td>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{font.updatedByName}</td>
                                    <td className="whitespace-nowrap text-right text-sm font-medium ">
                                        <div className="absolute -mt-2">
                                            <MenuListCtxMenu
                                                selectedFont={font}
                                                onEdit={(selectedFont) => {
                                                    setEditDialogOpen(true);
                                                    setFontToEdit(_.cloneDeep(selectedFont));
                                                }}
                                                onDelete={(selectedFont) => {
                                                    setDeleteDialogOpen(true);
                                                    setFontToDelete(_.cloneDeep(selectedFont))
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <EditFontDialog
                open={editDialogOpen}
                font={fontToEdit}
                fonts={fonts}
                onConfirm={handleEdit}
            />
            <DeleteDialog
                title="Delete Font"
                subTitle={<>Do you want to delete font <span className="text-indigo-700">{fontToDelete?.name}</span>?</>}
                open={deleteDialogOpen}
                setOpen={setDeleteDialogOpen}
                onConfirm={handleDeleteConfirm}
            />

        </>
    )
}