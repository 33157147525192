import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef } from 'react'

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function GenericModal({ open, onClose, onConfirm, onCancel, confirmButtonText, cancelButtonText, showCancelButton, title, maxWidth, disabled, children }) {
    const canShowCancelButton = !!showCancelButton
    const cancelButtonRef = useRef(null);
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={onClose}
            >
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className={"inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6 "
                            + (maxWidth ? maxWidth : "sm:max-w-screen-sm")}>
                            <div>
                                <div className="text-center">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        {title}
                                    </Dialog.Title>
                                    {children}
                                </div>
                            </div>
                            <div className="mt-4 grid grid-cols-6 gap-4">
                                {
                                    canShowCancelButton && (<button
                                        type="button"
                                        className="col-span-3 text-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium bg-white hover:bg-gray-50 
                                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
                                        onClick={() => onCancel?.call()}
                                        ref={cancelButtonRef}
                                        disabled={disabled}
                                    >
                                        {cancelButtonText || 'Cancel'}
                                    </button>)
                                }
                                <button
                                    type="button"
                                    className={
                                        classNames("inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-base font-medium text-white sm:text-sm",
                                            canShowCancelButton ?
                                                "col-span-3" : "col-span-4 col-start-2"
                                        )
                                    }
                                    onClick={() => onConfirm?.call()}
                                    disabled={disabled}
                                >
                                    {confirmButtonText || 'Confirm'}
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
