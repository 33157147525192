import Quill from 'quill';
import { useEffect, useRef, useState } from "react";
import * as Constants from "../../utils/constants"
import _ from "lodash"
import { useQuill } from '../../hooks/useQuill';

export default function QuillEditor({ id, styleSelectId, reInitialized, toolbarStateRef, setToolbarState, stylesRef, fontsRef, text, setText, disabled, setRichTextEditorToolBarDisabled }) {
    const disabledRef = useRef(disabled);

    const formatsWithMultiCharSelectionBeforeTextChangedRef = useRef();
    const selectionWithMultiCharBeforeTextChangedRef = useRef();
    const formatsWithZeroCharSelectionBeforeTextChangedRef = useRef();
    const selectionWithZeroCharBeforeTextChangedRef = useRef();
    const keyPressWithMultiCharsSelectionRef = useRef();
    const keyDownWithZeroCharSelectionRef = useRef();

    const { getQuill, getQuillHtmlContent, getLastCharacterIndexOfPreviousParagraph, getCurrentParagraphRange } = useQuill();

    const getInlineFormat = (formats) => {
        return formats[`${Constants.jssCssPrefix}-${Constants.jssCssInline}`];
    }

    const getInlineStyle = (formats) => {
        const inlineFormat = getInlineFormat(formats);
        let inlineStyle = undefined;
        if (inlineFormat && Array.isArray(inlineFormat) && inlineFormat.length > 0) {
            inlineStyle = stylesRef.current.find(x => x.id === inlineFormat[0]);
        } else if (inlineFormat && typeof inlineFormat === 'string') {
            inlineStyle = stylesRef.current.find(x => x.id === inlineFormat);
        }
        return inlineStyle;
    }

    const getBlockFormat = (formats) => {
        return formats[`${Constants.jssCssPrefix}-${Constants.jssCssBlock}`];
    }

    const getBlockStyle = (formats) => {
        const blockFormat = getBlockFormat(formats);
        let blockStyle = undefined;
        if (blockFormat && Array.isArray(blockFormat) && blockFormat.length > 0) {
            blockStyle = stylesRef.current.find(x => x.id === blockFormat[0]);
        } else if (blockFormat && typeof blockFormat === 'string') {
            blockStyle = stylesRef.current.find(x => x.id === blockFormat);
        }
        return blockStyle;
    }

    const getFontSizes = (currentFormats, currentContents) => {
        let fontSizes = [];

        if (Array.isArray(currentFormats.fs)) {
            fontSizes = currentFormats.fs;
        } else if (typeof currentFormats.fs === 'string') {
            fontSizes.push(currentFormats.fs);
        } else if (currentContents && currentContents.ops && currentContents.ops.length > 0) {
            fontSizes = currentContents.ops.filter(item => item.attributes?.fs);
            fontSizes = fontSizes.map(item => { return item.attributes.fs });
        }
        fontSizes = fontSizes.map(fontSize => {
            fontSize = fontSize.replace('pt', '');
            return fontSize;
        });

        return fontSizes;
    }

    const handleFontFamilyName = (name) => {
        let fontFamilyName = name;
        if (fontFamilyName) {
            if (fontFamilyName[0] === '"') {
                fontFamilyName = fontFamilyName.substring(1);
            }
            if (fontFamilyName[fontFamilyName.length - 1] === '"') {
                fontFamilyName = fontFamilyName.substring(0, (fontFamilyName.length - 1));
            }
        }
        if (!fontFamilyName) {
            fontFamilyName = '';
        }
        return fontFamilyName;
    }

    const getFontFamilies = (currentFormats, currentContents) => {
        let fontfamilies = [];

        if (Array.isArray(currentFormats.fontfamily)) {
            fontfamilies = currentFormats.fontfamily;
        } else if (typeof currentFormats.fontfamily === 'string') {
            fontfamilies.push(currentFormats.fontfamily);
        } else if (currentContents && currentContents.ops && currentContents.ops.length > 0) {
            fontfamilies = currentContents.ops.filter(item => item.attributes?.fontfamily);
            fontfamilies = fontfamilies.map(item => { return item.attributes.fontfamily });
        }
        fontfamilies = fontfamilies.map((fontfamily) => {
            fontfamily = handleFontFamilyName(fontfamily);
            return fontfamily;
        });

        return fontfamilies;
    }

    const findStyle = (cssNames) => {
        let foundStyle = undefined;
        if (Array.isArray(cssNames)) {
            for (let cssName in cssNames) {
                foundStyle = stylesRef.current.find(x => x.id === cssName);
                if (foundStyle) {
                    break;
                }
            }
        } else if (typeof cssNames === 'string') {
            foundStyle = stylesRef.current.find(x => x.id === cssNames);
        }
        return foundStyle;
    }

    const findStyles = (props) => {
        let foundStyles = [];
        if (props.currentFormats) {
            const inlineCsses = props.currentFormats[`${Constants.jssCssPrefix}-${Constants.jssCssInline}`];
            const inlineStyle = findStyle(inlineCsses);
            if (inlineStyle && !foundStyles.find(x => x.id === inlineStyle.id)) {
                foundStyles.push(inlineStyle);
            }

            const blockCsses = props.currentFormats[`${Constants.jssCssPrefix}-${Constants.jssCssBlock}`];
            const blockStyle = findStyle(blockCsses);
            if (blockStyle && !foundStyles.find(x => x.id === blockStyle.id)) {
                foundStyles.push(blockStyle);
            }
        }

        return foundStyles;
    }

    const handleChange = async (eventName, ...args) => {
        let quill = getQuill(id);
        if (!quill) return;

        if (eventName === 'text-change') {
            setText(getQuillHtmlContent(id));
        }

        const selection = quill.getSelection();

        if (disabledRef.current) return;

        if (!selection) {
            let toolbarDisabled = true;

            const styleSelectElement = document.getElementById(styleSelectId);
            if (styleSelectElement && document.activeElement && Array.isArray(args) && args.length > 0 && Array.isArray(args[0]) && args[0].length >= 2) {
                if (styleSelectElement.contains(document.activeElement)) {
                    // args[0][1] is the old selection range
                    toolbarStateRef.current.quillSelectionWhenClickStyle = args[0][1];
                    toolbarDisabled = false;
                }
            }

            setRichTextEditorToolBarDisabled(toolbarDisabled);

            const clonedToolbarState = _.cloneDeep(toolbarStateRef.current);
            clonedToolbarState.hyperLinkState = Constants.formatButtonState.disabled;
            setToolbarState(clonedToolbarState);

            return;
        }

        setRichTextEditorToolBarDisabled(false);

        // show toolbar
        const currentFormats = quill.getFormat();
        const currentContents = quill.getContents(selection.index, selection.length);

        const fontSizes = getFontSizes(currentFormats, currentContents);
        const fontFamilies = getFontFamilies(currentFormats, currentContents);

        const clonedToolbarState = _.cloneDeep(toolbarStateRef.current);

        if (currentFormats.link) {
            clonedToolbarState.hyperLink = currentFormats.link;
            clonedToolbarState.hyperLinkState = Constants.formatButtonState.focused;
        } else {
            clonedToolbarState.hyperLink = '';
            clonedToolbarState.hyperLinkState = Constants.formatButtonState.normal;
        }

        const foundStyles = findStyles({ currentFormats: currentFormats, fontFamilies: fontFamilies, fontSizes: fontSizes });
        if (foundStyles?.length > 0) {
            clonedToolbarState.selectedStyles = _.cloneDeep(foundStyles);
        }

        setToolbarState(clonedToolbarState);
    }

    useEffect(() => {
        let Parchment = Quill.import('parchment');
        const config = {
            scope: Parchment.Scope.BLOCK,
        }
        let LineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', config);
        let LineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', config);
        Parchment.register(LineHeightClass);
        Parchment.register(LineHeightStyle);

        let fsStyle = new Parchment.Attributor.Style('fs', 'font-size', {
            scope: Parchment.Scope.INLINE
        });
        Quill.register(fsStyle);

        let ffStyle = new Parchment.Attributor.Style('fontfamily', 'font-family', {
            scope: Parchment.Scope.INLINE
        });
        Parchment.register(ffStyle);

        let colorStyle = new Parchment.Attributor.Style('color', 'color', {
            scope: Parchment.Scope.INLINE
        });
        Parchment.register(colorStyle);

        let bgColorStyle = new Parchment.Attributor.Style('bgcolor', 'background-color', {
            scope: Parchment.Scope.INLINE
        });
        Parchment.register(bgColorStyle);

        let alignStyle = new Parchment.Attributor.Style('align', 'text-align', {
            whitelist: ['left', 'right', 'center', 'justify'],
            scope: Parchment.Scope.BLOCK,
        });
        Parchment.register(alignStyle);

        let textIndentStyle = new Parchment.Attributor.Style('textIndent', 'text-indent', {
            scope: Parchment.Scope.BLOCK
        });
        Parchment.register(textIndentStyle);

        let paddingleftStyle = new Parchment.Attributor.Style('paddingleft', 'padding-left', {
            scope: Parchment.Scope.BLOCK
        });
        Parchment.register(paddingleftStyle);

        let paddingrightStyle = new Parchment.Attributor.Style('paddingright', 'padding-right', {
            scope: Parchment.Scope.BLOCK
        });
        Parchment.register(paddingrightStyle);

        let paddingtopStyle = new Parchment.Attributor.Style('paddingtop', 'padding-top', {
            scope: Parchment.Scope.BLOCK
        });
        Parchment.register(paddingtopStyle);

        let paddingbottomStyle = new Parchment.Attributor.Style('paddingbottom', 'padding-bottom', {
            scope: Parchment.Scope.BLOCK
        });
        Parchment.register(paddingbottomStyle);

        let marginleftStyle = new Parchment.Attributor.Style('margnleft', 'margin-left', {
            scope: Parchment.Scope.BLOCK
        });
        Parchment.register(marginleftStyle);

        const PrefixClass = new Parchment.Attributor.Class('prefix', 'prefix', {
            scope: Parchment.Scope.BLOCK,
            whitelist: ['custom-link', 'another-class']
        });
        Quill.register(PrefixClass, true);

        const inlineStyleClass = new Parchment.Attributor.Class(`${Constants.jssCssPrefix}-${Constants.jssCssInline}`, `${Constants.jssCssPrefix}-${Constants.jssCssInline}`, {
            scope: Parchment.Scope.INLINE,
        });
        Parchment.register(inlineStyleClass);

        const blockStyleClass = new Parchment.Attributor.Class(`${Constants.jssCssPrefix}-${Constants.jssCssBlock}`, `${Constants.jssCssPrefix}-${Constants.jssCssBlock}`, {
            scope: Parchment.Scope.BLOCK,
        });
        Parchment.register(blockStyleClass);

        let Clipboard = Quill.import('modules/clipboard');

        class PlainClipboard extends Clipboard {
            onPaste(e) {
                let quill = getQuill(id);
                if (!quill) return;

                e.preventDefault();

                const selection = quill.getSelection();
                if (!selection) return;

                const currentIndexFormats = quill.getFormat(selection.index, 0);
                const blockStyle = getBlockStyle(currentIndexFormats);

                if (selection.length > 0) {
                    quill.deleteText(selection.index, selection.length);
                }

                if (blockStyle) {
                    quill.formatLine(selection.index, 0, `${Constants.jssCssPrefix}-${Constants.jssCssBlock}`, blockStyle.id);
                    if (blockStyle.type === Constants.styleType.paragraph) {
                        quill.formatLine(selection.index, 0, 'list', false);
                    } else if (blockStyle.type === Constants.styleType.list) {
                        if (blockStyle.list === Constants.listType.unordered) {
                            quill.formatLine(selection.index, 0, 'list', 'bullet');
                        } else {
                            quill.formatLine(selection.index, 0, 'list', 'ordered');
                        }
                    }
                }

                const text = e.clipboardData.getData('text');
                quill.insertText(selection.index, text, currentIndexFormats);
            }
        }

        Quill.register('modules/clipboard', PlainClipboard, true);

        const editorElement = document.getElementById(id);
        let quill = new Quill(editorElement, {
            modules: {
                toolbar: false,
            },
        });

        const htmlContent = quill.clipboard.convert(text);
        quill.setContents(htmlContent);

        quill.on("editor-change", (eventName, ...args) => {
            handleChange(eventName, args);
        });
    }, [reInitialized]);

    useEffect(() => {
        disabledRef.current = disabled;

        let quill = getQuill(id);
        if (!quill) return;

        quill.enable(!disabled);
    }, [disabled])

    const handleKeyDown = (e) => {
        let quill = getQuill(id);
        if (!quill) return;

        const selection = quill.getSelection();
        if (!selection) return;

        if (selection.length === 0) {
            if (!keyDownWithZeroCharSelectionRef.current) {
                formatsWithZeroCharSelectionBeforeTextChangedRef.current = quill.getFormat(selection.index, 0);
                selectionWithZeroCharBeforeTextChangedRef.current = _.cloneDeep(selection);
                keyDownWithZeroCharSelectionRef.current = true;
            }
        }
    }

    const handleKeyPress = (e) => {
        let quill = getQuill(id);
        if (!quill) return;

        const selection = quill.getSelection();
        if (!selection) return;

        // Multi-characters selection and entering character will cause quill js warning/error 
        if (selection.length > 0) {
            if (!keyPressWithMultiCharsSelectionRef.current) {
                formatsWithMultiCharSelectionBeforeTextChangedRef.current = quill.getFormat(selection.index, 0);
                quill.deleteText(selection.index, selection.length);
                selectionWithMultiCharBeforeTextChangedRef.current = _.cloneDeep(selection);
                keyPressWithMultiCharsSelectionRef.current = true;
            }
        }
    }

    const getPreviousFormats = () => {
        let quill = getQuill(id);
        if (!quill) return;

        let previousFormats = formatsWithZeroCharSelectionBeforeTextChangedRef.current;

        if (!previousFormats || _.isEmpty(previousFormats)) {
            const lastCharacterIndexOfPreviousParagraph = getLastCharacterIndexOfPreviousParagraph(id);
            previousFormats = quill.getFormat(lastCharacterIndexOfPreviousParagraph, 0);
        }

        if (!previousFormats || _.isEmpty(previousFormats)) {
            if (toolbarStateRef.current.selectedStyles) {
                toolbarStateRef.current.selectedStyles.forEach(style => {
                    previousFormats[`${Constants.jssCssPrefix}-${Constants.jssCssInline}`] = style.id;
                    if (style.type !== Constants.styleType.character) {
                        previousFormats[`${Constants.jssCssPrefix}-${Constants.jssCssBlock}`] = style.id;
                    }
                })
            }
        }

        if (!previousFormats[`${Constants.jssCssPrefix}-${Constants.jssCssInline}`] && previousFormats[`${Constants.jssCssPrefix}-${Constants.jssCssBlock}`]) {
            previousFormats[`${Constants.jssCssPrefix}-${Constants.jssCssInline}`] = previousFormats[`${Constants.jssCssPrefix}-${Constants.jssCssBlock}`];
        }

        return previousFormats;
    }

    const handleKeyUp = (e) => {
        let quill = getQuill(id);
        if (!quill) return;

        const selection = quill.getSelection();
        if (!selection) return;

        let currentIndexFormats = quill.getFormat(selection.index, 0);

        // Multi-characters selection and entering character will cause quill js warning/error 
        if (selectionWithMultiCharBeforeTextChangedRef.current) {
            const length = selection.index - selectionWithMultiCharBeforeTextChangedRef.current.index;
            if (length > 0) {
                if (formatsWithMultiCharSelectionBeforeTextChangedRef.current) {
                    const inlineFormat = formatsWithMultiCharSelectionBeforeTextChangedRef.current[`${Constants.jssCssPrefix}-${Constants.jssCssInline}`];
                    if (inlineFormat && Array.isArray(inlineFormat) && inlineFormat.length > 0) {
                        quill.formatText(selectionWithMultiCharBeforeTextChangedRef.current.index, length, `${Constants.jssCssPrefix}-${Constants.jssCssInline}`, inlineFormat[0]);
                    } else if (inlineFormat && typeof inlineFormat === 'string') {
                        quill.formatText(selectionWithMultiCharBeforeTextChangedRef.current.index, length, `${Constants.jssCssPrefix}-${Constants.jssCssInline}`, inlineFormat);
                    }

                    const blockFormat = formatsWithMultiCharSelectionBeforeTextChangedRef.current[`${Constants.jssCssPrefix}-${Constants.jssCssBlock}`];
                    if (blockFormat && Array.isArray(blockFormat) && blockFormat.length > 0) {
                        quill.formatLine(selectionWithMultiCharBeforeTextChangedRef.current.index, length, `${Constants.jssCssPrefix}-${Constants.jssCssBlock}`, blockFormat[0]);
                    } else if (blockFormat && typeof blockFormat === 'string') {
                        quill.formatLine(selectionWithMultiCharBeforeTextChangedRef.current.index, length, `${Constants.jssCssPrefix}-${Constants.jssCssBlock}`, blockFormat);
                    }
                }
            }
        }

        // check the current index
        // Sometimes deleting or writing text will remove ql-block-* or ql-inline-* css class of the html element
        if (selectionWithZeroCharBeforeTextChangedRef.current) {
            const previousFormats = getPreviousFormats();

            if (!currentIndexFormats[`${Constants.jssCssPrefix}-${Constants.jssCssInline}`]) {
                const length = selection.index - selectionWithZeroCharBeforeTextChangedRef.current.index;
                if (length > 0) {
                    if (!_.isEmpty(previousFormats)) {
                        const inlineFormat = previousFormats[`${Constants.jssCssPrefix}-${Constants.jssCssInline}`];
                        if (inlineFormat && Array.isArray(inlineFormat) && inlineFormat.length > 0) {
                            quill.formatText(selectionWithZeroCharBeforeTextChangedRef.current.index, length, `${Constants.jssCssPrefix}-${Constants.jssCssInline}`, inlineFormat[0]);
                        } else if (inlineFormat && typeof inlineFormat === 'string') {
                            quill.formatText(selectionWithZeroCharBeforeTextChangedRef.current.index, length, `${Constants.jssCssPrefix}-${Constants.jssCssInline}`, inlineFormat);
                        }
                    }
                }
            }
            if (!currentIndexFormats[`${Constants.jssCssPrefix}-${Constants.jssCssBlock}`]) {
                const length = selection.index - selectionWithZeroCharBeforeTextChangedRef.current.index;
                if (length > 0) {
                    if (!_.isEmpty(previousFormats)) {
                        const blockFormat = previousFormats[`${Constants.jssCssPrefix}-${Constants.jssCssBlock}`];
                        if (blockFormat && Array.isArray(blockFormat) && blockFormat.length > 0) {
                            quill.formatLine(selectionWithZeroCharBeforeTextChangedRef.current.index, length, `${Constants.jssCssPrefix}-${Constants.jssCssBlock}`, blockFormat[0]);
                        } else if (blockFormat && typeof blockFormat === 'string') {
                            quill.formatLine(selectionWithZeroCharBeforeTextChangedRef.current.index, length, `${Constants.jssCssPrefix}-${Constants.jssCssBlock}`, blockFormat);
                        }
                    }
                }
            }
        }

        // check the current paragraph
        currentIndexFormats = quill.getFormat(selection.index, 0);
        let inlineStyle = getInlineStyle(currentIndexFormats);
        let blockStyle = getBlockStyle(currentIndexFormats);
        if (blockStyle && inlineStyle) {
            // check if the paragraph or list style of ql-block-<styleId> css class is the same as html element (<p> or <ol>) 
            if (blockStyle.type === Constants.styleType.paragraph) {
                if (currentIndexFormats.list) {
                    quill.formatLine(selection.index, selection.length, 'list', false);
                }
            } else if (blockStyle.type === Constants.styleType.list) {
                if (blockStyle.list === Constants.listType.unordered && currentIndexFormats.list !== 'bullet') {
                    quill.formatLine(selection.index, selection.length, 'list', 'bullet');
                } else if (blockStyle.list === Constants.listType.numbered && currentIndexFormats.list !== 'ordered') {
                    quill.formatLine(selection.index, selection.length, 'list', 'ordered');
                }
            }

            // check if the inline style (ql-inline-<styleId>, except character-type style) of each element in the paragraph is the same as the block style (ql-block-<styleId>)
            const currentParagraphRange = getCurrentParagraphRange(id);
            if (currentParagraphRange) {
                const currentParagraphContents = quill.getContents(currentParagraphRange.index, currentParagraphRange.length);
                if (currentParagraphContents.ops) {
                    let currentIndex = currentParagraphRange.index;
                    for (let content of currentParagraphContents.ops) {
                        if (content.insert !== '\n') {
                            if (!content.attributes || !content.attributes[`${Constants.jssCssPrefix}-${Constants.jssCssInline}`]) {
                                quill.formatText(currentIndex, content.insert.length, `${Constants.jssCssPrefix}-${Constants.jssCssInline}`, blockStyle.id);
                            } else {
                                const style = stylesRef.current.find(x => x.id === content.attributes[`${Constants.jssCssPrefix}-${Constants.jssCssInline}`]);
                                if (style && style.type !== Constants.styleType.character) {
                                    if (blockStyle.id !== style.id) {
                                        quill.formatText(currentIndex, content.insert.length, `${Constants.jssCssPrefix}-${Constants.jssCssInline}`, blockStyle.id);
                                    }
                                }
                            }
                        }
                        if (content.insert) {
                            currentIndex = currentIndex + content.insert.length;
                        }
                    }
                }
            }
        }

        keyPressWithMultiCharsSelectionRef.current = false;
        keyDownWithZeroCharSelectionRef.current = false;
        formatsWithMultiCharSelectionBeforeTextChangedRef.current = null;
        selectionWithMultiCharBeforeTextChangedRef.current = null;
        formatsWithZeroCharSelectionBeforeTextChangedRef.current = null;
        selectionWithZeroCharBeforeTextChangedRef.current = null;
    }

    return (
        <div
            id={id}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyPress}
            onKeyUp={handleKeyUp}
        />
    );
}