
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { AuthenticationProvider, oidcLog, useReactOidc } from '@axa-fr/react-oidc-context';
import { getUserManager } from '@axa-fr/react-oidc-core';
import { oidcConfig } from './utils/config';
import * as Constants from './utils/constants';
import { OidcSecure } from '@axa-fr/react-oidc-context';

import { setAuthHeader } from './api/api'

import { Toaster } from './toaster';
import Oidc from "./screens/Oidc";
import { NotFound } from './screens/Errors/NotFound';
import { Unauthorized } from './screens/Errors/Unauthorized';
import { MissingLicense } from './screens/Errors/MissingLicense';
import TextList from './screens/Admin/TextList';
import TextDesign from './screens/Admin/TextDesign';
import GroupList from './screens/Admin/GroupList';
import FontList from './screens/Admin/FontList';
import StyleList from './screens/Admin/StyleList';
import StyleDesign from './screens/Admin/StyleList/StyleDesign';
import AdminLayout from './layouts/AdminLayout';
import DocumentationAPI from './screens/DocumentationAPI';
import Dashboard from './screens/Admin/Dashboard';

import { getUserProfile } from './api/userProfile';
import { OidcRoutesContext } from './contexts';
import { getCustomerInfo } from './api/customer';
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { useFeature } from './hooks/useFeature';

function App() {
  return (
    <div className="app h-screen">
      <BrowserRouter>
        <AuthenticationProvider
          configuration={oidcConfig}
          loggerLevel={oidcLog.ERROR}
          isEnabled={true}
          callbackComponentOverride={Oidc}
          notAuthenticated={() => { return (<h1>Not Authenticated.</h1>) }}
          notAuthorized={() => { return (<h1>Not Authorized.</h1>) }}
          authenticating={Oidc}
        >
          <OidcSecure>
            <SetAuthToken>
              <SetOidcRoutesContext>
                <Routes>
                  <Route exact={true} path="/unauthorized" component={Unauthorized} />
                  <Route exact={true} path="/not-found" component={NotFound} />
                  <Route exact={true} path="/documentation-api" component={DocumentationAPI} />
                  <Route exact={true} path="/missing-license" component={MissingLicense} />

                  <Route path="/" element={
                    <AdminLayoutRoute exact={true} path="/" nav="home" component={Dashboard} featureEnabled={true} />
                  } />
                  <Route path="/texts" element={
                    <AdminLayoutRoute exact={true} path="/texts" nav="textlist" component={TextList} featureEnabled={true} />
                  } />
                  <Route path="/text" element={
                    <AdminLayoutRoute exact={true} path="/text" nav="textlist" component={TextDesign} featureEnabled={true} />
                  } />
                  <Route path="/text/:id" element={
                    <AdminLayoutRoute exact={true} path="/text/:id" nav="textlist" component={TextDesign} featureEnabled={true} />
                  } />
                  <Route path="/groups" element={
                    <AdminLayoutRoute exact={true} path="/groups" nav="grouplist" component={GroupList} featureEnabled={true} />
                  } />
                  <Route path="/fonts" element={
                    <AdminLayoutRoute exact={true} path="/fonts" nav="fontlist" component={FontList} featureEnabled={true} />
                  } />
                  <Route path="/styles" element={
                    <AdminLayoutRoute exact={true} path="/styles" nav="stylelist" component={StyleList} featureEnabled={true} />
                  } />
                  <Route path="/style" element={
                    <AdminLayoutRoute exact={true} path="/style" nav="stylelist" component={StyleDesign} featureEnabled={true} />
                  } />
                  <Route path="/style/:id" element={
                    <AdminLayoutRoute exact={true} path="/style/:id" nav="stylelist" component={StyleDesign} featureEnabled={true} />
                  } />

                </Routes>
              </SetOidcRoutesContext>
            </SetAuthToken>
          </OidcSecure>
        </AuthenticationProvider>

      </BrowserRouter>
      <Toaster />
    </div>
  );
}

function AdminLayoutRoute({
  component: Component,
  featureEnabled,
  nav,
  subMenuNav,
  requiredFeatureCode,
  ...rest
}) {

  const feature = useFeature();

  return (
    <ProtectedRoute featureEnabled={featureEnabled} hasFeature={feature.hasFeature(requiredFeatureCode)} >
      <AdminLayout nav={nav} subMenuNav={subMenuNav} {...rest} >
        <Component {...rest} />
      </AdminLayout>
    </ProtectedRoute>
  )
}

const ProtectedRoute = ({ featureEnabled, hasFeature, children }) => {
  return featureEnabled && hasFeature ? (
    <>
      {children}
    </>
  ) :
    (<Navigate to={'/'} />)
}

const SetOidcRoutesContext = ({ children }) => {
  const loadAction = useToastAction();
  const [userProfile, setUserProfile] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});

  const loadUserContextData = async () => {
    loadAction.execute(async () => {
      const userProfilePromise = getUserProfile();
      const customerPromise = getCustomerInfo();
      const res = await Promise.all([userProfilePromise, customerPromise]);
      setUserProfile(res[0]);
      setCustomerInfo(res[1]);

    }, "Failed to load user data");
  }

  useEffect(() => {
    loadUserContextData();
  }, []);

  return !loadAction.isExecuting && (
    <OidcRoutesContext.Provider
      value={{
        userProfile,
        customerInfo
      }}>
      {children}
    </OidcRoutesContext.Provider>
  )
}

/**
 * Small route wrapper that gets the oidc user and sets the axios auth header 
 */
const SetAuthToken = ({ children }) => {
  const { oidcUser, events } = useReactOidc();

  setAuthHeader(oidcUser.access_token);

  useEffect(() => {
    if (!events) return events.addUserSignedOut(addUserSignedOut);
    return () => {
      events.removeUserSignedOut(addUserSignedOut);
    }
  }, [events])

  const addUserSignedOut = () => {
    const userManager = getUserManager();
    userManager.removeUser();
    window.location.reload(false);
  }

  return (
    <>
      {children}
    </>
  )
}
export default App;
