import React, { useContext } from 'react';
import { config } from '../../utils/config';
import { MenuBar } from "@metaforcelabs/metaforce-core";
import { OidcRoutesContext } from '../../contexts';

export const MissingLicense = () => {
  const oidcRoutesContext = useContext(OidcRoutesContext);

  return (
    <div className="relative min-h-screen flex flex-col bg-white">
        <MenuBar
            navigation={[]}
            customerName={oidcRoutesContext?.customerInfo?.name}
            customerOrgNumber={oidcRoutesContext?.customerInfo?.orgNumber}
            homeButtonLink={config?.centerpointUiBaseUrl}
            userProfileImage={oidcRoutesContext?.userProfile?.userProfileImage}
            helpLink={config?.docsUrl}
        />
        <div className="pt-2 pb-10 flex bg-gray-50 h-screen">
          <div className={'flex  w-full mx-auto '}>
            <p className="text-2xl mt-6 ml-6">Missing license to access Textlibrary</p>
          </div>
        </div>
    </div>
  )
}