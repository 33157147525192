import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../utils/constants";

export const textsSlice = createSlice({
    name: 'texts',
    initialState: {
        searchRequest: {
            groupName: '',
            groupId: '',
            cultureInfoName: '',
            languageId: '',
            validFrom: '',
            validTo: '',
            name: '',
            sortProperty: Constants.textSortProperty.modifiedTime,
            sortOrder: Constants.sortOrder.desc,
            skip: 0,
            take: Constants.paginationPageSize,
        },
        pagination: {
            totalRecordsCount: 0,
            currentPage: 0,
            pageSize: Constants.paginationPageSize,
            lastPage: 0,
            nextPage: 0,
            previousPage: 0,
            currentPageStart: 0,
            currentPageEnd: 0,
        }
    },
    reducers: {
        setSearchRequest: (state, action) => {
            const { searchRequest } = action.payload;
            state.searchRequest = searchRequest;
        },
        setPagination: (state, action) => {
            const { pagination } = action.payload;
            state.pagination = pagination;
        },
    }
})

export const {
    setSearchRequest,
    setPagination
} = textsSlice.actions

export default textsSlice.reducer