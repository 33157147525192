import { PageHeader, useToastAction } from "@metaforcelabs/metaforce-core"
import { useState, useEffect, useContext } from "react";
import { deleteGroup, getGroups } from "../../../api/textlibrary";
import { OidcRoutesContext } from "../../../contexts";
import { useTableSort } from "../../../hooks/useTableSort";
import * as Constants from "../../../utils/constants";
import DateTimeParser from "../../../utils/DateTimeParser";
import toast from "react-hot-toast";
import * as _ from "lodash";
import DeleteDialog from "../DeleteDialog";
import { MenuListCtxMenu } from "./menuListCtxMenu";
import EditGroupDialog from "./editGroupDialog";

export default function GroupList({ templateText, onClose }) {
    const { userProfile } = useContext(OidcRoutesContext);
    const [groups, setGroups] = useState([]);

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [groupToEdit, setGroupToEdit] = useState({});

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [groupToDelete, setGroupToDelete] = useState();

    const loadAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const res = await getGroups();
            setGroups(res);
        }, "Failed to load data");
    }

    useEffect(() => {
        loadData();
    }, []);

    const tableSort = useTableSort(groups, Constants.groupSortProperty.name, Constants.sortOrder.asc);

    const handleRename = async (confirm) => {
        if (confirm) {
            await loadData();
        }
        setEditDialogOpen(false);
        setGroupToEdit(null);
    }

    const handleDeleteConfirm = async (confirm) => {
        try {
            if (confirm && groupToDelete) {
                await deleteGroup(groupToDelete);
                await loadData();
            }
        } catch (err) {
            if (err.response?.status === 400 && err.response?.data?.Message.startsWith("TextLibraryErrors:")) {
                const message = err.response?.data?.Message.replace("TextLibraryErrors:", "");
                toast.error(message, {
                    duration: Infinity,
                });
            } else {
                toast.error("Failed to delete group");
            }
        } finally {
            setDeleteDialogOpen(false);
            setGroupToDelete(null);
        }
    }

    return (
        <>
            <div className="flex flex-row justify-between items-end">
                <PageHeader title="Groups" 
                    optionalSideElement={
                        <div className="flex justify-end gap-x-3">
                            <button
                                type="button"
                                className="w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                                onClick={() => {
                                    setEditDialogOpen(true);
                                    setGroupToEdit({ name: '' });
                                }}
                            >
                                Add Group
                            </button>
                            {templateText && (
                                <button
                                    type="button"
                                    className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                                    onClick={() => {
                                        onClose()
                                    }}
                                >
                                    Back to Text
                                </button>
                            )}
                        </div>
                    }
                />
            </div>

            {/* <div className="mt-3 mb-5 flex justify-end gap-x-3">
                <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={() => {
                        setEditDialogOpen(true);
                        setGroupToEdit({ name: '' });
                    }}
                >
                    Add Group
                </button>
                {templateText && (
                    <button
                        type="button"
                        className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                        onClick={() => {
                            onClose()
                        }}
                    >
                        Back to Text
                    </button>
                )}
            </div> */}

            <div className="align-middle inline-block min-w-full shadow-sm sm:rounded-lg relative mt-5" >
                <div className="border border-gray-200 ">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.groupSortProperty.name)
                                        }}
                                    >
                                        Name {tableSort.getSortIcon(Constants.groupSortProperty.name)}
                                    </button>
                                </th>

                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.groupSortProperty.createdTime)
                                        }}
                                    >
                                        Created {tableSort.getSortIcon(Constants.groupSortProperty.createdTime)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.groupSortProperty.createdByUserName)
                                        }}
                                    >
                                        Created by {tableSort.getSortIcon(Constants.groupSortProperty.createdByUserName)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.groupSortProperty.modifiedTime)
                                        }}
                                    >
                                        Updated {tableSort.getSortIcon(Constants.groupSortProperty.modifiedTime)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.groupSortProperty.modifiedByUserName)
                                        }}
                                    >
                                        Updated by {tableSort.getSortIcon(Constants.groupSortProperty.modifiedByUserName)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 px-6 py-4 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    <span className="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {tableSort?.tableData?.map((group, index) => (
                                <tr key={group.id} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap font-medium text-sm text-indigo-600 overflow-ellipsis overflow-hidden max-w-120 lg:max-w-150 xl:max-w-250 ">
                                        <button
                                            type="button"
                                            className={"cursor-pointer hover:underline"}
                                            onClick={() => {
                                                setEditDialogOpen(true);
                                                setGroupToEdit(group);
                                            }}>
                                            {group.name}
                                        </button>
                                    </td>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{DateTimeParser.toLocaleDateTimeString(group.createdTime, userProfile.languageCode)}</td>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{group.createdByUserName}</td>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{DateTimeParser.toLocaleDateTimeString(group.modifiedTime, userProfile.languageCode)}</td>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{group.modifiedByUserName}</td>
                                    <td className="whitespace-nowrap text-right text-sm font-medium ">
                                        <div className="absolute -mt-2">
                                            <MenuListCtxMenu
                                                selectedGroup={group}
                                                onEdit={(selectedGroup) => {
                                                    setEditDialogOpen(true);
                                                    setGroupToEdit(_.cloneDeep(selectedGroup));
                                                }}
                                                onDelete={(selectedGroup) => {
                                                    setDeleteDialogOpen(true);
                                                    setGroupToDelete(_.cloneDeep(selectedGroup))
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <EditGroupDialog
                open={editDialogOpen}
                group={groupToEdit}
                groups={groups}
                onConfirm={handleRename}
            />

            <DeleteDialog
                title="Delete Group"
                subTitle={<>Do you want to delete group <span className="text-indigo-700">{groupToDelete?.name}</span>?</>}
                open={deleteDialogOpen}
                onConfirm={handleDeleteConfirm}
            />

        </>
    )
}