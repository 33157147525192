import { BaseUrl, download, get, post } from './api'

export const getDashboardData = async () => {
  return await get(`api/TextLibrary/dashboard`, BaseUrl.Api);
}

export const getGroups = async () => {
  return await get(`api/TextLibrary/groups`, BaseUrl.Api);
}

export const getLanguages = async () => {
  return await get(`api/TextLibrary/languages`, BaseUrl.Api);
}

export const searchTexts = async (searchRequest) => {
  return await post(`api/TextLibrary/search`, BaseUrl.Api, searchRequest);
}

export const getText = async (id) => {
  return await get(`api/TextLibrary/text/${id}`, BaseUrl.Api);
}

export const createText = async (templateTextDto) => {
  return await post(`api/TextLibrary/text/create`, BaseUrl.Api, templateTextDto);
}

export const updateText = async (templateTextDto) => {
  return await post(`api/TextLibrary/text/update`, BaseUrl.Api, templateTextDto);
}

export const deleteText = async (templateTextDto) => {
  return await post(`api/TextLibrary/text/delete`, BaseUrl.Api, templateTextDto);
}

export const duplicateText = async (id) => {
  return await get(`api/TextLibrary/text/duplicate/${id}`, BaseUrl.Api);
}

export const exportText = async (args) => {
  return await download(`api/TextLibrary/text/export`, BaseUrl.Api, args.data, args.fileName);
}

export const importText = async (importRequestModel) => {
  return await post(`api/TextLibrary/text/import`, BaseUrl.Api, importRequestModel);
}

export const createGroup = async (templateTextGroupDto) => {
  return await post(`api/TextLibrary/group/create`, BaseUrl.Api, templateTextGroupDto);
}

export const updateGroup = async (templateTextGroupDto) => {
  return await post(`api/TextLibrary/group/update`, BaseUrl.Api, templateTextGroupDto);
}

export const deleteGroup = async (templateTextGroupDto) => {
  return await post(`api/TextLibrary/group/delete`, BaseUrl.Api, templateTextGroupDto);
}

export const getFonts = async () => {
  return await get(`api/TextLibrary/fonts`, BaseUrl.Api);
}

export const createFont = async (fontDto) => {
  return await post(`api/TextLibrary/font/create`, BaseUrl.Api, fontDto);
}

export const updateFont = async (fontDto) => {
  return await post(`api/TextLibrary/font/update`, BaseUrl.Api, fontDto);
}

export const deleteFont = async (fontDto) => {
  return await post(`api/TextLibrary/font/delete`, BaseUrl.Api, fontDto);
}

export const getStyles = async () => {
  return await get(`api/TextLibrary/styles`, BaseUrl.Api);
}

export const getStyle = async (id) => {
  return await get(`api/TextLibrary/style/${id}`, BaseUrl.Api);
}

export const createStyle = async (styleDto) => {
  return await post(`api/TextLibrary/style/create`, BaseUrl.Api, styleDto);
}

export const updateStyle = async (styleDto) => {
  return await post(`api/TextLibrary/style/update`, BaseUrl.Api, styleDto);
}

export const deleteStyle = async (styleDto) => {
  return await post(`api/TextLibrary/style/delete`, BaseUrl.Api, styleDto);
}

export const encryptTextCookies = async (templateTextCookies) => {
  return await post(`api/TextLibrary/text/cookies/encrypt`, BaseUrl.Api, templateTextCookies);
}

export const decryptTextCookies = async (encrypted) => {
  return await get(`api/TextLibrary/text/cookies/decrypt/${encrypted}`, BaseUrl.Api);
}
