import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { ArrowsExpandIcon, TrashIcon } from "@heroicons/react/solid";
import { useClassNames } from '@metaforcelabs/metaforce-core';
import React, { Fragment } from "react";

export const MenuListCtxMenu = ({ selectedGroup, onEdit, onDelete }) => {
    const { classNames } = useClassNames();
    return (
        <Menu as="div" className="ml-3 relative inline-block text-left">
            <div>
                <Menu.Button className="-my-2 p-1 bg-white flex items-center hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 border border-gray-300 rounded-md">
                    <DotsVerticalIcon className="w-5 h-5" />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <div className="px-1 py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                        active ? "bg-gray-100 text-gray-900 font-semibold" : "text-gray-700")}
                                    onClick={e => {
                                        onEdit(selectedGroup)
                                    }}
                                >
                                    <ArrowsExpandIcon
                                        className="h-5 w-5 text-gray-500 mr-3"
                                        aria-hidden="true"
                                    />
                                    Edit
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                        active ? "bg-gray-100 text-gray-900 font-semibold" : "text-gray-700")}
                                    onClick={e => {
                                        onDelete(selectedGroup)
                                    }}
                                >
                                    <TrashIcon
                                        className="h-5 w-5 text-gray-500 mr-3"
                                        aria-hidden="true"
                                    />
                                    Delete
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}