import { Popover } from '@headlessui/react'
import { useEffect } from 'react';
import { useState } from 'react';
import * as Constants from "../../../utils/constants"
import { LinkIcon } from "@heroicons/react/outline";
import * as yup from "yup";

export default function HyperLinkComponent({ state, disabled, url, onButtonClick, onSave, onRemove }) {
    const [link, setLink] = useState(url);
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);

    const formatButtonClassNames = (state) => {
        if (state === Constants.formatButtonState.focused) {
            return "bg-gray-200 px-3 py-2 text-#536876 text-sm h-full border border-gray-300 rounded focus:ring-gray-400 focus:border-gray-400";
        } else if (state === Constants.formatButtonState.normal) {
            return "bg-white px-3 py-2 text-#536876 text-sm h-full border border-gray-300 rounded focus:ring-gray-400 focus:border-gray-400"
        } else if (state === Constants.formatButtonState.disabled) {
            return "bg-white px-3 py-2 text-#E8EDF2 text-sm h-full border border-gray-300 rounded focus:outline-none cursor-not-allowed "
        }
        return "";
    }

    useEffect(() => {
        setLink(url);
    }, [url])

    let schema = yup.object().shape({
        link: yup.string().url().required("Link is required"),
    });

    const validate = async () => {
        var isValid = false

        await schema
            .validate({ link: link }, { abortEarly: false })
            .then(function () {
                isValid = true;
                setErrors({});
            })
            .catch(function (err) {
                var newErrors = {}

                err?.inner?.forEach(element => {
                    newErrors[element.path] = `${element.errors[0]}`
                });

                setErrors(newErrors);
            });

        return isValid;
    }

    return (
        <Popover className="relative h-full">
            <Popover.Button
                type="button"
                title="create hyperlink"
                className={formatButtonClassNames(state)}
                disabled={disabled}
                onClick={(e) => {
                    const result = onButtonClick();
                    if (!result) {
                        setOpen(false);
                    } else {
                        setOpen(true);
                    }
                    setLink(url);
                }}
            >
                <LinkIcon className="h-5 w-5" />
            </Popover.Button>

            {open && (
                <Popover.Panel
                    className="absolute z-50 mt-0.5"
                    onBlur={() => {
                        setErrors({});
                    }}>
                    {({ close }) => (
                        <div className="border rounded shadow-sm bg-brand-background px-4 py-2 ">
                            <div className="flex flex-row gap-x-3">
                                <label className="text-sm block inline-flex items-center">Link: </label>
                                <input
                                    type="text"
                                    className="block shadow-sm text-sm focus:ring-gray-400 focus:border-gray-400 border-gray-300 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                                    value={link}
                                    onChange={(e) => {
                                        setLink(e.target.value)
                                    }}
                                />

                                <button
                                    type="button"
                                    className="bg-white px-3 py-2 text-#536876 text-sm border border-gray-300 rounded focus:outline-none"
                                    onClick={async () => {
                                        let isValid = await validate();
                                        if (!isValid) {
                                            return;
                                        }
                                        close();
                                        onSave(link);
                                    }}
                                >
                                    Save
                                </button>

                                {url &&
                                    <button
                                        type="button"
                                        className="bg-white px-3 py-2 text-#536876 text-sm border border-gray-300 rounded focus:outline-none"
                                        onClick={() => {
                                            close();
                                            onRemove();
                                        }}
                                    >
                                        Remove
                                    </button>
                                }
                            </div>
                            {errors.link &&
                                <div>
                                    <div className="text-sm text-red-600 font-medium inline-flex items-center">{errors.link}</div>
                                </div>}

                        </div>
                    )}
                </Popover.Panel>
            )}
        </Popover>
    )
}