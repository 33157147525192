import { ButtonV2, CoreButtonTypes, HighlightCard, HighlightCardVariants, useToastAction } from "@metaforcelabs/metaforce-core";
import { NavLink, useNavigate } from "react-router-dom";
import CardBigSmallLabel from "../../../components/CardBigSmallLabelWithSkeletonLoader";
import { useEffect, useState } from "react";
import { getDashboardData } from "../../../api/textlibrary";
import { SkeletonLoader } from "../../../components/SkeletonLoader";
import DateTimeParser from "../../../utils/DateTimeParser";

export default function Dashboard() {
    const [dashboardData, setDashboardData] = useState({ texts: [{}, {}, {}, {}, {}] });
    const loadAction = useToastAction();
    const navigate = useNavigate()

    const loadData = async () => {
        loadAction.execute(async () => {
            let result = await getDashboardData();
            setDashboardData(result);
        }, "Failed to load data");
    }

    useEffect(() => {
        loadData()
    }, []);


    return (
        <>
            <section className='mt-10'>
                <HighlightCard
                    variant={HighlightCardVariants.yellow}
                    header="Textlibrary"
                    body={
                        <div className='w-full lg:w-150% mb-3'>
                            <p>Textlibrary is a global text archive to be used in Editor to produce output documents with Pages.</p>
                        </div>
                    }
                    buttonText='Open'
                    onClick={() => navigate("/texts")}
                    rightContent={<></>}
                />
            </section>

            {dashboardData.fontsCount === 0 && (
                <section className='mt-5'>
                    <ErrorCard
                        header="Fonts are missing"
                        body={
                            <p>You need to register a font before you can start to register text in the Textlibrary</p>
                        }
                        buttonText='Create'
                        onClick={() => navigate("/fonts?new=true")}
                        rightContent={<></>}
                    />
                </section>
            )}

            {dashboardData.stylesCount === 0 && (
                <section className='mt-5'>
                    <ErrorCard
                        header="Styles are missing"
                        body={
                            <p>You need to register styles before you can start to register text in the Textlibrary</p>
                        }
                        buttonText='Create'
                        onClick={() => navigate("/style")}
                        rightContent={<></>}
                    />
                </section>
            )}

            <div className="flex">
                <dl className="mt-5 mr-1 grid grid-cols-6 gap-2 sm:grid-cols-5">
                    <CardBigSmallLabel smallLabel="Text elements" bigLabel={dashboardData.textsCount} loading={loadAction.isExecuting} />
                    <CardBigSmallLabel smallLabel="Groups" bigLabel={dashboardData.groupsCount} loading={loadAction.isExecuting} />
                    <CardBigSmallLabel smallLabel="Languages" bigLabel={dashboardData.languagesCount} loading={loadAction.isExecuting} />
                    <CardBigSmallLabel smallLabel="Fonts" bigLabel={dashboardData.fontsCount} loading={loadAction.isExecuting} />
                    <CardBigSmallLabel smallLabel="Styles" bigLabel={dashboardData.stylesCount} loading={loadAction.isExecuting} />
                </dl>
            </div>

            <>
                <div className='flex justify-between mt-12 '>
                    <h3 className="text-xl leading-6 font-bold text-gray-900">Last texts that has been edited</h3>
                    <NavLink to={"/texts"}
                        className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    >
                        See all <span aria-hidden="true"> &rarr;</span>
                    </NavLink>
                </div>

                <div className="mt-5 w-full flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Created by
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Created
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Updated by
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Updated
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dashboardData?.texts?.map((templateText, textIndex) => {
                                            return (
                                                <tr key={templateText.id} className={textIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative overflow-ellipsis overflow-hidden" style={{ minWidth: '250px', maxWidth: '300px' }}>
                                                        <SkeletonLoader element={templateText}>
                                                            {templateText.name}
                                                        </SkeletonLoader>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <SkeletonLoader showOverride={loadAction.isExecuting}>
                                                            <span>{templateText.createdByUserName}</span>
                                                        </SkeletonLoader>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <SkeletonLoader element={templateText}>
                                                            {DateTimeParser.formatWithMonthName(templateText.createdTime)}
                                                        </SkeletonLoader>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <SkeletonLoader element={templateText}>
                                                            {templateText.modifiedByUserName}
                                                        </SkeletonLoader>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <SkeletonLoader element={templateText}>
                                                            {DateTimeParser.formatWithMonthName(templateText.modifiedTime)}
                                                        </SkeletonLoader>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <SkeletonLoader element={templateText}>
                                                            <NavLink to={`/text/${templateText.id}`}
                                                                className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                                            >
                                                                Edit
                                                            </NavLink>
                                                        </SkeletonLoader>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        </>
    )
}

const ErrorCard = ({ header, body, buttonText, onClick }) => {
    return (
        <div className="h-full w-full px-6 sm:px-8 lg:px-12 py-6 lg:py-8 rounded-md bg-document-lightpink">
            <div className="font-bold text-2xl sm:text-4xl">
                {header}
            </div>
            <div className="mt-3 block sm:inline-block w-full font-medium text-sm text-gray-600">
                {body}
            </div>
            <div className="flex justify-end">
                <ButtonV2 htmlClassNames="px-6 text-gray-900 bg-brand-button1 hover:bg-brand-button1-hover focus:ring-brand-button1" type={CoreButtonTypes.primary} label={buttonText} onClick={onClick} />
            </div>
        </div>
    )
}