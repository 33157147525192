import moment from 'moment';
import 'moment/locale/nb';
import 'moment/locale/da';
import 'moment/locale/sv';
import 'moment/locale/es';
import 'moment/locale/ar';

const DateTimeParser = {
  toLocalJSONFormat(date) {
    const parsedDate = new Date(date);
    const offset = Math.abs(parsedDate.getTimezoneOffset() / 60);
    const localJSONFormat = parsedDate.setHours(parsedDate.getHours() + offset);
    return new Date(localJSONFormat).toJSON();
  },

  setSOD(date) {
    return new Date(date).setHours(0, 0, 0, 0);
  },

  setEOD(date) {
    return new Date(date).setHours(23, 59, 59);
  },

  toLocaleDateString(date, cultureCode = 'en') {
    if (date === null || date === '') {
      return '';
    }

    const dateObj = typeof date === 'string' ? new Date(date) : date;
    return moment(dateObj).locale(cultureCode).format('L');
  },

  toLocaleTimeString(jsonFormatDateString, cultureCode = 'en') {
    const date = new Date(jsonFormatDateString);
    return moment(date).locale(cultureCode).format('LTS');
  },

  toLocaleDateTimeString(jsonFormatDateString, cultureCode = 'en') {
    return (
      this.toLocaleDateString(jsonFormatDateString, cultureCode) +
      ' ' +
      this.toLocaleTimeString(jsonFormatDateString, cultureCode)
    );
  },

  toDateObject(value) {
    return new Date(moment(value).year(), moment(value).month(), moment(value).date());
    // return new Date(moment(value).weekYear(), moment(value).month(), moment(value).date());
  },

  toDateUTCObject(value) {
    return new Date(Date.UTC(moment(value).year(), moment(value).month(), moment(value).date(), 0, 0, 0));
    // return new Date(moment(value).weekYear(), moment(value).month(), moment(value).date());
  },

  toValueOf(date) {
    const localeFormat = moment.localeData(this.getCurrentCultureCode()).longDateFormat('L');
    return moment(date, localeFormat).valueOf();
  },

  toDateFormat(date, format) {
    const localeFormat = moment.localeData(this.getCurrentCultureCode()).longDateFormat('L');
    return moment(date, localeFormat).format(format);
  },

  addDays(daysNumber) {
    moment.locale(this.getCurrentCultureCode());
    return moment().add(daysNumber, 'days').format('L');
  },

  addYears(yearsNumber) {
    moment.locale(this.getCurrentCultureCode());
    return moment().add(yearsNumber, 'years').format('L');
  },

  subtractYears(yearsNumber) {
    moment.locale(this.getCurrentCultureCode());
    return moment().subtract(yearsNumber, 'years').format('L');
  },

  add(startDate, amount, unit, format = 'L') {
    const localeFormat = moment.localeData(this.getCurrentCultureCode()).longDateFormat(format);

    return moment(startDate, localeFormat).add(amount, unit).format(format);
  },

  isValidFormat(date, format) {
    const localeFormat = moment.localeData(this.getCurrentCultureCode()).longDateFormat(format);

    if (moment(date, localeFormat, true).isValid()) {
      return true;
    }

    return false;
  },

  isAfter(date) {
    const localeFormat = moment.localeData(this.getCurrentCultureCode()).longDateFormat('L');

    if (moment(date, localeFormat).isAfter(moment(), 'day')) {
      return true;
    }

    return false;
  },

  getFormat(format) {
    moment.locale(this.getCurrentCultureCode());

    const localeData = moment.localeData();

    return localeData.longDateFormat(format);
  },

  subtract(startDate, amount, unit) {
    const localeFormat = moment.localeData(this.getCurrentCultureCode()).longDateFormat('L');

    return moment(startDate, localeFormat).subtract(amount, unit);
  },

  getCurrentCultureCode() {
    return 'sv';
  },

  formatWithMonthName(dateString) {
    const date = new Date(dateString);
  
      const day = date.getUTCDate();
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getUTCFullYear();
  
      return `${day} ${month} ${year}`;
  }
};
export default DateTimeParser;
