import GenericModal from "../../../components/Modals/genericModal"

export default function DeleteDialog({ title, subTitle, open, onConfirm }) {
    return (
        <GenericModal
            open={open}
            onClose={(value) => onConfirm(false)}
            onConfirm={() => onConfirm(true)}
            onCancel={() => onConfirm(false)}
            confirmButtonText={"OK"}
            cancelButtonText={"Cancel"}
            showCancelButton={true}
            title={title}
        >
            <div className="w-full py-4">
                <p className="text-sm font-medium text-gray-900">{subTitle}</p>
            </div>
        </GenericModal>
    )
}