import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux'
import store from './store'
import { JssProvider } from 'react-jss'
import * as Constants from "./utils/constants"

import './index.css'
import "./styles.scss"
import 'axios-progress-bar/dist/nprogress.css'
import './quill.snow.css';

const generateId = (rule, sheet) => {
  if (rule.key.startsWith(Constants.jssCssBlock)) {
    const rest = rule.key.replace(Constants.jssCssBlock, '');
    return `${Constants.jssCssPrefix}-${Constants.jssCssBlock}-${rest}`
  } else if (rule.key.startsWith(Constants.jssCssInline)) {
    const rest = rule.key.replace(Constants.jssCssInline, '');
    return `${Constants.jssCssPrefix}-${Constants.jssCssInline}-${rest}`
  }
  return `${Constants.jssCssPrefix}-${rule.key}`
}

ReactDOM.render(
  <Provider store={store}>
    <JssProvider generateId={generateId}>
      <CookiesProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </CookiesProvider>
    </JssProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
