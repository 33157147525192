import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader, useToastAction } from "@metaforcelabs/metaforce-core"
import { exportText, getGroups, getLanguages, searchTexts } from "../../../api/textlibrary"
import * as _ from "lodash"
import DateTimeParser from "../../../utils/DateTimeParser";
import { TextLibraryContext } from "../../../contexts";
import TextTable from "./textTable";
import { setSearchRequest, setPagination } from "../../../slices/textsSlice";
import { SearchIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";
import { useDebounceCallback } from "@react-hook/debounce";
import ImportDialog from "./importDialog";

export default function TextList() {
    const searchRequest = useSelector((state) => state.texts.searchRequest);
    const pagination = useSelector((state) => state.texts.pagination);
    const dispatch = useDispatch();
    const [searchResult, setSearchResult] = useState();
    const [groupOptions, setGroupOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [showImportDialog, setShowImportDialog] = useState(false);

    const loadAction = useToastAction();
    const searchAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            let [groups, languages] = await Promise.all([
                getGroups(),
                getLanguages(),
            ]);

            groups = _.orderBy(groups, 'name', 'asc');
            const newGroupOptions = groups.map(group => { return { name: group.name, value: group.id } });
            newGroupOptions.unshift({ name: 'Select Group', value: '' });
            setGroupOptions(newGroupOptions);

            languages = _.orderBy(languages, 'name', 'asc');
            const newLanguageOptions = languages.map(language => { return { name: language.name, value: language.id } });
            newLanguageOptions.unshift({ name: 'Select Language', value: '' });
            setLanguageOptions(newLanguageOptions);

            await handleSearchTexts({ searchRequest: searchRequest });
        }, "Failed to load data");
    }

    useEffect(() => {
        loadData()
    }, []);

    const handlePaginationChange = async (newPagination) => {
        dispatch(setPagination({
            pagination: newPagination,
        }));

        debouncedSearchTexts();
    }

    const handleTableSort = async (props) => {
        dispatch(setSearchRequest({
            searchRequest: {
                ...searchRequest,
                sortProperty: props.sortProperty,
                sortOrder: props.sortOrder,
                skip: 0,
            }
        }));
        dispatch(setPagination({
            pagination: {
                ...pagination,
                currentPage: 0,
            },
        }));
        debouncedSearchTexts();
    }

    const handleSearchInputChange = async (e) => {
        dispatch(setSearchRequest({
            searchRequest: {
                ...searchRequest,
                name: e.target.value,
                identifier: e.target.value,
                skip: 0,
            }
        }));
        dispatch(setPagination({
            pagination: {
                ...pagination,
                currentPage: 0,
            },
        }));

        debouncedSearchTexts();
    }

    const handleSearchTexts = async () => {
        searchAction.execute(async () => {
            const clonedSearchRequest = _.cloneDeep(searchRequest);
            clonedSearchRequest.validFrom = DateTimeParser.toDateObject(clonedSearchRequest.validFrom);
            clonedSearchRequest.validTo = DateTimeParser.toDateObject(clonedSearchRequest.validTo);
            clonedSearchRequest.page = _.cloneDeep(pagination);

            const res = await searchTexts(clonedSearchRequest);
            setSearchResult(res);

            dispatch(setPagination({
                pagination: res.page,
            }));
        }, "Failed to search texts");
    }

    const debouncedSearchTexts = useDebounceCallback((args) => {
        handleSearchTexts(args)
    }, 500);

    const handleExportTexts = async (templateTextToExport) => {
        const args = {
            data: {},
            fileName: "export-texts.json",
        };
        if (templateTextToExport) {
            args.data.templateTextId = templateTextToExport.id;
            args.fileName = `export-${templateTextToExport.id}.json`;
        }

        await exportText(args);
    }

    const handleImportDialogClose = async () => {
        setShowImportDialog(false);
        await loadData();
    }

    return !loadAction.isExecuting && (
        <TextLibraryContext.Provider value={{
            searchResult: searchResult,
            setSearchResult: setSearchResult,
            isSearching: searchAction.isExecuting,
            groupOptions: groupOptions,
            languageOptions: languageOptions,
            onTableSort: (props) => {
                handleTableSort(props);
            },
            onPaginationChange: (updatedPagination) => {
                handlePaginationChange(updatedPagination);
            },
            debouncedSearchTexts: () => {
                debouncedSearchTexts();
            },
            handleExportTexts: handleExportTexts,
        }}>
            <div className="flex flex-row justify-between items-end">
                <PageHeader title="Texts" optionalSideElement={
                    <div className="flex justify-end gap-x-3">
                    <div className="flex-1 sm:flex-none w-72">
                        <label htmlFor="desktop-search-candidate" className="sr-only">
                            Search
                        </label>
                        <div className="flex rounded-md shadow-sm">
                            <div className="relative flex-grow focus-within:z-10">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <SearchIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <input
                                    type="text"
                                    name="desktop-search-candidate"
                                    id="desktop-search-candidate"
                                    className="focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                                    placeholder="Search..."
                                    value={searchRequest.name}
                                    onChange={handleSearchInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={searchAction.isExecuting}
                        onClick={() => handleExportTexts()}
                    >
                        Export
                    </button>
                    <button
                        type="button"
                        className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={searchAction.isExecuting}
                        onClick={() => setShowImportDialog(true)}
                    >
                        Import
                    </button>
                    <NavLink
                        className="w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={searchAction.isExecuting}
                        onClick={(e) => {
                            if (searchAction.isExecuting)
                                e.preventDefault();
                        }}
                        to={"/text"}
                    >
                        Add text
                    </NavLink>
                </div>
                }
                />
            </div>

            {/* <div className="mt-3 mb-5 flex justify-end gap-x-3">
                <div className="flex-1 sm:flex-none w-72">
                    <label htmlFor="desktop-search-candidate" className="sr-only">
                        Search
                    </label>
                    <div className="flex rounded-md shadow-sm">
                        <div className="relative flex-grow focus-within:z-10">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <SearchIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <input
                                type="text"
                                name="desktop-search-candidate"
                                id="desktop-search-candidate"
                                className="focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                                placeholder="Search..."
                                value={searchRequest.name}
                                onChange={handleSearchInputChange}
                            />
                        </div>
                    </div>
                </div>
                <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={searchAction.isExecuting}
                    onClick={() => handleExportTexts()}
                >
                    Export
                </button>
                <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={searchAction.isExecuting}
                    onClick={() => setShowImportDialog(true)}
                >
                    Import
                </button>
                <NavLink
                    className="w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={searchAction.isExecuting}
                    onClick={(e) => {
                        if (searchAction.isExecuting)
                            e.preventDefault();
                    }}
                    to={"/text"}
                >
                    Add text
                </NavLink>
            </div> */}

            <TextTable />

            <ImportDialog
                open={showImportDialog}
                setOpen={setShowImportDialog}
                onClose={handleImportDialogClose}
            />
        </TextLibraryContext.Provider>
    )
}