import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { config } from "../../utils/config";
import { OidcRoutesContext } from '../../contexts';
import { ContentWrapper, MenuBar } from '@metaforcelabs/metaforce-core';


export default function AdminLayout({ nav, children }) {
    const oidcRoutesContext = useContext(OidcRoutesContext);
    const navigationMenu = [
        {
          linkElement: <Link to="/">Home</Link>,
          name: "Home",
          current: nav === 'home',
          featureEnabled: true
        },
        {
          linkElement: <Link to="/texts">Texts</Link>,
          name: "Texts",
          current: nav === 'textlist',
          featureEnabled: true
        },
        {
          linkElement: <Link to="/groups">Groups</Link>,
          name: "Groups",
          current: nav === 'grouplist',
          featureEnabled: true
        },
        {
          linkElement: <Link to="/styles">Styles</Link>,
          name: "Styles",
          current: nav === 'stylelist',
          featureEnabled: true
        },
        {
          linkElement: <Link to="/fonts">Fonts</Link>,
          name: "Fonts",
          current: nav === 'fontlist',
          featureEnabled: true
        },
      ];

    return (
        <div className="relative flex flex-col bg-gray-50 h-screen overflow-auto">
          <MenuBar
              navigation={navigationMenu}
              customerName={oidcRoutesContext.customerInfo.name}
              customerOrgNumber={oidcRoutesContext.customerInfo.orgNumber}
              homeButtonLink={config.centerpointUiBaseUrl}
              userProfileImage={oidcRoutesContext.userProfile.userProfileImage}
              userName={oidcRoutesContext.userProfile.fullName}
              helpLink={config.docsUrl}
          />
          <ContentWrapper>
            {children}
          </ContentWrapper>
        </div>
    )
}