import { useClassNames } from "@metaforcelabs/metaforce-core";

export const SkeletonLoader = ({ element, showOverride = null, children, className, ...props }) => {
  const { classNames } = useClassNames();
  let showSkeletonLoader;
  if (showOverride != null) {
    showSkeletonLoader = showOverride;
  } else {
    showSkeletonLoader = Object.keys(element || {}).length === 0;
  }

  return (
    <span className={classNames(`${showSkeletonLoader ? classNames("skeleton-loader", className, !className ? "w-3/5" : "") : ""}`)}>
      {!showSkeletonLoader ? children : ''}
    </span>
  )
}