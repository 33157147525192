import { useClassNames } from "@metaforcelabs/metaforce-core"
import GenericModal from "../../../components/Modals/genericModal"
import { useEffect, useState } from "react";
import { updateText } from "../../../api/textlibrary";
import toast from "react-hot-toast";

export default function RenameDialog({ open, setOpen, templateText, onConfirm }) {
    const { classNames } = useClassNames();
    const [error, setError] = useState();
    const [name, setName] = useState("");

    useEffect(() => {
        if (templateText)
            setName(templateText.name)
    }, [templateText])

    const handleChange = (e) => {
        setName(e.target.value);
    }

    const handleConfirm = async (confirm) => {
        if (confirm) {
            if (!name) {
                setError("Required");
                return;
            }

            try {
                await updateText({
                    ...templateText,
                    name: name
                })
            } catch (err) {
                if (err.response?.status === 400 && err.response?.data?.Message?.startsWith("TextLibraryErrors:")) {
                    const message = err.response?.data?.Message.replace("TextLibraryErrors:", "");
                    toast.error(message);
                } else {
                    toast.error("Failed to rename text");
                }
                return;
            }
        }

        onConfirm(confirm);
    }

    return (
        <GenericModal
            open={open}
            onClose={() => handleConfirm(false)}
            onConfirm={() => handleConfirm(true)}
            onCancel={() => handleConfirm(false)}
            confirmButtonText={"OK"}
            cancelButtonText={"Cancel"}
            showCancelButton={true}
        >
            <div className="">
                <h3 className="text-left text-lg font-medium py-2" >Rename text</h3>
                <hr></hr>
                <div className="py-5">
                    <div className="mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                        <div className="mt-1 relative rounded-md w-full">
                            <input
                                type={"text"}
                                name="name"
                                value={name}
                                className={classNames(`focus:ring-gray-400 focus:border-gray-400 block sm:text-sm rounded-md w-full`,
                                    error ? "border-red-500" : "border-gray-300"
                                )}
                                onChange={handleChange}
                            />
                            {error ? (
                                <div className="text-red-500">{error}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </GenericModal>
    )
}