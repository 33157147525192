import { PageHeader, useClassNames, useToastAction } from "@metaforcelabs/metaforce-core"
import { useState, useEffect, useContext } from "react";
import { deleteStyle, getStyles, } from "../../../api/textlibrary";
import { OidcRoutesContext } from "../../../contexts";
import { useTableSort } from "../../../hooks/useTableSort";
import * as Constants from "../../../utils/constants";
import DateTimeParser from "../../../utils/DateTimeParser";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import toast from "react-hot-toast";
import * as _ from "lodash";
import DeleteDialog from "../DeleteDialog";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { MenuListCtxMenu } from "./menuListCtxMenu";

export default function StyleList() {
    const { userProfile } = useContext(OidcRoutesContext);
    const [styles, setStyles] = useState([]);

    const [addDialogOpen, setAddDialogOpen] = useState(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [styleToDelete, setStyleToDelete] = useState();

    const { classNames } = useClassNames();
    const loadAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const res = await getStyles();
            setStyles(res);
        }, "Failed to load data");
    }

    useEffect(() => {
        loadData();
    }, []);

    const tableSort = useTableSort(styles, Constants.styleSortProperty.name, Constants.sortOrder.asc);

    const handleDeleteConfirm = async (confirm) => {
        try {
            if (confirm && styleToDelete) {
                await deleteStyle(styleToDelete);

                const clonedStyles = _.cloneDeep(styles);
                const index = clonedStyles.findIndex(x => x.id === styleToDelete.id);
                if (index > -1) {
                    clonedStyles.splice(index, 1);
                    setStyles(clonedStyles);
                }
            }
        } catch (err) {
            if (err.response?.status === 400 && err.response?.data?.Message.startsWith("TextLibraryErrors:")) {
                const message = err.response?.data?.Message.replace("TextLibraryErrors:", "");
                toast.error(message, {
                    duration: Infinity,
                });
            } else {
                toast.error("Failed to delete style");
            }
        } finally {
            setDeleteDialogOpen(false);
            setStyleToDelete(null);
        }
    }

    return (
        <>
            <div className="flex flex-row justify-between items-end">
                <PageHeader title="Styles" optionalSideElement={<Link
                    to={`/style`}
                    className="w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    Add Style
                </Link>} />
            </div>

            {/* <div className="mt-3 mb-5 flex justify-end gap-x-3">
                <Link
                    to={`/style`}
                    className="w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    Add Style
                </Link>
            </div> */}

            <div className="align-middle inline-block min-w-full shadow-sm sm:rounded-lg relative mt-5" >
                <div className="border border-gray-200 ">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.styleSortProperty.name)
                                        }}
                                    >
                                        Name {tableSort.getSortIcon(Constants.styleSortProperty.name)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.styleSortProperty.createdDate)
                                        }}
                                    >
                                        Created {tableSort.getSortIcon(Constants.styleSortProperty.createdDate)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.styleSortProperty.createdByName)
                                        }}
                                    >
                                        Created By {tableSort.getSortIcon(Constants.styleSortProperty.createdByName)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.styleSortProperty.updatedDate)
                                        }}
                                    >
                                        Updated {tableSort.getSortIcon(Constants.styleSortProperty.updatedDate)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 bg-gray-50">
                                    <button
                                        type="button"
                                        className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                        onClick={() => {
                                            tableSort.orderBy(Constants.styleSortProperty.updatedByName)
                                        }}
                                    >
                                        Updated By {tableSort.getSortIcon(Constants.styleSortProperty.updatedByName)}
                                    </button>
                                </th>
                                <th scope="col" className="z-10 sticky top-0 px-6 py-4 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    <span className="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {tableSort?.tableData?.map((style, index) => (
                                <tr key={style.id} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap font-medium text-sm text-indigo-600 overflow-ellipsis overflow-hidden max-w-120 lg:max-w-150 xl:max-w-250 ">
                                        <NavLink className={"cursor-pointer hover:underline"}
                                            to={`/style/${style.id}`}>
                                            {style.name}
                                        </NavLink>
                                    </td>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{DateTimeParser.toLocaleDateTimeString(style.createdDate, userProfile.languageCode)}</td>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{style.createdByName}</td>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{DateTimeParser.toLocaleDateTimeString(style.updatedDate, userProfile.languageCode)}</td>
                                    <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{style.updatedByName}</td>
                                    <td className="whitespace-nowrap text-right text-sm font-medium ">
                                        <div className="absolute -mt-2">
                                            <MenuListCtxMenu
                                                selectedStyle={style}
                                                onDelete={(selectedStyle) => {
                                                    setDeleteDialogOpen(true);
                                                    setStyleToDelete(_.cloneDeep(selectedStyle))
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <DeleteDialog
                title="Delete Style"
                subTitle={<>Do you want to delete style <span className="text-indigo-700">{styleToDelete?.name}</span>?</>}
                open={deleteDialogOpen}
                setOpen={setDeleteDialogOpen}
                onConfirm={handleDeleteConfirm}
            />

        </>
    )
}