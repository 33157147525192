import { useFileReader, useToastAction } from "@metaforcelabs/metaforce-core"
import GenericModal from "../../../components/Modals/genericModal"
import { useState } from "react";
import * as Constants from "../../../utils/constants";
import { importText } from "../../../api/textlibrary";

export default function ImportDialog({ open, setOpen, onClose }) {
    const fileReader = useFileReader();
    const [importRequestModel, setImportRequestModel] = useState();
    const [error, setError] = useState();
    const [importMessage, setImportMessage] = useState();

    const importAction = useToastAction();

    const handleClose = () => {
        onClose();
        setImportRequestModel(undefined);
        setError(undefined);
        setImportMessage(undefined);
    }

    const handleImport = () => {
        if (!importRequestModel || !importRequestModel?.content) {
            setError("No file selected!");
            return;
        }

        importAction.execute(async () => {
            try {
                await importText(importRequestModel);
                setImportMessage(`${importRequestModel.fileName} was imported.`)
                setError(undefined);
            } catch (err) {
                if (err.response?.status === 400) {
                    setError(err.response?.data?.Message);
                } else {
                    setError("Failed to import text");
                }
            }

            setImportRequestModel(undefined);
        }, "Failed to import text");
    }

    const onFileChange = async (e) => {
        const fileResult = await fileReader.handleFileInputChange(e)
        if (!fileResult.fileName.toLowerCase().endsWith(".json")) {
            setError("Invalid file format!");
            return;
        }
        setImportRequestModel({
            fileName: fileResult.fileName,
            content: fileResult.content,
        })
        setError(undefined);
        setImportMessage(undefined);
    }

    return (
        <GenericModal
            open={open}
            onClose={() => {
                if (importAction.isExecuting)
                    return;
                handleClose();
            }}
            onConfirm={() => handleImport()}
            onCancel={() => {
                if (importAction.isExecuting)
                    return;
                handleClose();
            }}
            confirmButtonText={"Import"}
            cancelButtonText={"Close"}
            showCancelButton={true}
            disabled={importAction.isExecuting}
        >
            <div className="">
                <h3 className="text-left text-lg font-medium py-2" >Import text</h3>
                <hr></hr>
                {
                    error && <div className="pt-3 text-red-500">{error}</div>
                }
                <div className="py-4 flex items-center justify-between">
                    <span className="flex-grow flex flex-col" id="availability-label">
                        <span className="text-left text-sm font-medium text-gray-500">{importRequestModel?.fileName ? `${importRequestModel.fileName} will be imported` : 'Select file to import (.json)'}</span>
                    </span>
                    <div className="cursor-pointer w-24 inline-flex items-center mr-2 mt-3 tems-center px-3 border border-gray-300 shadow-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null">
                        <div className="cursor-pointer absolute">
                            <span className='cursor-pointer text-sm'>Select File</span>
                        </div>
                        <input
                            type="file"
                            accept=".json"
                            className='cursor-pointer hover:cursor-pointer opacity-0 py-2 '
                            name="file"
                            onClick={(e) => {
                                e.target.value = '';
                            }}
                            onChange={onFileChange}
                            disabled={importAction.isExecuting}
                        />
                    </div>
                </div>

                {importMessage && <div className="text-left text-sm font-medium text-gray-500 pb-3">{importMessage}</div>}

                {importAction.isExecuting && (
                    <div className="inline-flex items-center text-indigo-700 pt-2 pb-6">
                        <svg className="animate-spin -ml-1 mr-3 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span>Importing...</span>
                    </div>
                )
                }

            </div>
        </GenericModal>
    )
}