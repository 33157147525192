import * as Constants from "../../utils/constants"
import _ from "lodash"
import { createUseStyles } from 'react-jss'


export default function CreateStyleCss({ children, stylesRef, fontsRef }) {
    const qlStyles = {};
    if (stylesRef.current && stylesRef.current.length > 0) {
        stylesRef.current.forEach((style, index) => {
            const font = fontsRef.current.find(x => x.id === style.fontId);
            qlStyles[`${Constants.jssCssBlock}${style.id}`] = {}
            if (style.underline) {
                qlStyles[`${Constants.jssCssBlock}${style.id}`].textDecoration = 'underline';
            }
            if (style.alignment === Constants.alignmentType.left) {
                qlStyles[`${Constants.jssCssBlock}${style.id}`].textAlign = 'left';
            } else if (style.alignment === Constants.alignmentType.center) {
                qlStyles[`${Constants.jssCssBlock}${style.id}`].textAlign = 'center';
            } else if (style.alignment === Constants.alignmentType.right) {
                qlStyles[`${Constants.jssCssBlock}${style.id}`].textAlign = 'right';
            } else if (style.alignment === Constants.alignmentType.justify) {
                qlStyles[`${Constants.jssCssBlock}${style.id}`].textAlign = 'justify';
            }
            // if (style.list === Constants.listType.unordered) {
            //     qlStyles[`${Constants.jssCssBlock}${style.id}`].listStyleType = 'disc';
            // } else if (style.list === Constants.listType.numbered) {
            //     qlStyles[`${Constants.jssCssBlock}${style.id}`].listStyleType = 'decimal';
            // }

            qlStyles[`${Constants.jssCssInline}${style.id}`] = {
                fontFamily: font ? font.name : '',
                fontSize: `${style.fontSize}pt`,
                fontWeight: style.bold ? 'bold' : 'normal',
                fontStyle: style.italic ? 'italic' : 'normal',
                color: style.textColor,
            }
            if (style.underline) {
                qlStyles[`${Constants.jssCssInline}${style.id}`].textDecoration = 'underline';
            }
        })
    }
    const useStyles = createUseStyles(qlStyles);
    const classes = useStyles();

    return (
        <>{children}</>
    );
}