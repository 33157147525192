import * as Constants from "../../utils/constants"
import _ from "lodash"
import { useQuill } from "../../hooks/useQuill"
import HyperLinkComponent from "../../screens/Admin/TextList/hyperLinkComponent";
import { useEffect, useState } from "react";
import Select from 'react-select';
import { toast } from "react-hot-toast";

export default function RichTextEditorToolBar({ quillId, styleSelectId, stylesRef, fontsRef, toolbarStateRef, setToolbarState, reInitialized, disabled, setDisabled }) {
    const { getQuill, isEmptyTextWithZeroSpaceStart, getFormatFromStyle, getCurrentParagraphRange, getBlockFormatFromStyle, getInlineFormatFromStyle } = useQuill();
    const [quillSelectionWhenClickHyperlink, setQuillSelectionWhenClickHyperlink] = useState();
    const [styleOptions, setStyleOptions] = useState([]);
    const [selectedStyleOptions, setSelectedStyleOptions] = useState([]);
    const styleSelectStyles = {
        control: (base, state) => ({
            ...base,
            // Overwrittes the different states of border
            border: state.isFocused ? "2px solid #9ca3af" : "1px solid #d1d5db",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                border: state.isFocused ? "2px solid #9ca3af" : "",
            },
            pointerEvents: state.isDisabled ? "auto" : "",
            cursor: state.isDisabled ? "not-allowed" : "default",
            color: state.isDisabled ? "#E8EDF2" : "#536876",
            backgroundColor: state.isDisabled ? "white" : "white",
        }),
        multiValueRemove: (baseStyles) => ({
            ...baseStyles,
            display: 'none'
        }),
        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            padding: "2px 8px",
        })
    }

    const sortStyleByName = (styleOptions) => {
        styleOptions.sort(function (a, b) {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
    }

    const getSelectOptions = (styles) => {
        let styleOptions = Array.isArray(styles) ? styles.map(style => { return { label: style.name, value: style.id } }) : [];
        sortStyleByName(styleOptions);
        return styleOptions;
    }

    useEffect(() => {
        if (stylesRef.current?.length > 0) {
            const options = getSelectOptions(stylesRef.current);
            setStyleOptions(options);
            setSelectedStyleOptions(options.length > 0 ? [options[0]] : []);
            toolbarStateRef.current.selectedStyles = [stylesRef.current[0]];
        }
    }, [stylesRef.current, reInitialized]);

    useEffect(() => {
        if (toolbarStateRef.current.selectedStyles?.length > 0) {
            const newSelectedStyleOptions = getSelectOptions(toolbarStateRef.current.selectedStyles);
            setSelectedStyleOptions(newSelectedStyleOptions);
        }
    }, [toolbarStateRef.current.selectedStyles])

    const handleStyleChange = (options) => {
        let quill = getQuill(quillId);
        if (!quill) {
            return;
        }

        let selection = quill.getSelection();

        if (!selection && toolbarStateRef.current.quillSelectionWhenClickStyle) {
            selection = _.cloneDeep(toolbarStateRef.current.quillSelectionWhenClickStyle);
            quill.setSelection(selection);
        }
        toolbarStateRef.current.quillSelectionWhenClickStyle = null;

        if (!selection) {
            setSelectedStyleOptions(options);
            return;
        }

        let selectedStyle = null;
        if (Array.isArray(options) && Array.isArray(selectedStyleOptions)) {
            selectedStyle = options.find(option => !selectedStyleOptions.find(x => x.value === option.value));
        }
        if (!selectedStyle) {
            return;
        }

        const style = stylesRef.current.find(x => x.id === selectedStyle.value);
        if (!style) {
            return;
        }

        if (style.type === Constants.styleType.character) {
            const newFormat = getFormatFromStyle(style);

            if (selection.length === 0) {
                quill.insertText(selection.index, '\uFEFF', newFormat.key, newFormat.value);
                quill.setSelection(selection.index + 1, 0);
            } else {
                quill.formatText(selection.index, selection.length, newFormat.key, newFormat.value);
                quill.setSelection(selection.index, selection.length);
            }

            setSelectedStyleOptions(options);
        } else {
            const paragraphRange = getCurrentParagraphRange(quillId, selection);
            if (paragraphRange) {
                const inlineFormat = getInlineFormatFromStyle(style);

                const paragraphText = quill.getText(paragraphRange.index, paragraphRange.length);
                if (paragraphText !== Constants.emptyQuillTextContent) {
                    quill.removeFormat(paragraphRange.index, paragraphRange.length);
                    if (inlineFormat) {
                        quill.formatText(paragraphRange.index, paragraphRange.length, inlineFormat.key, inlineFormat.value);
                    }
                }

                if (inlineFormat) {
                    quill.formatText(paragraphRange.index, paragraphRange.length, inlineFormat.key, inlineFormat.value);
                }

                if (!style.list) {
                    quill.formatLine(paragraphRange.index, paragraphRange.length, 'list', false);
                } else if (style.list === Constants.listType.unordered) {
                    quill.formatLine(paragraphRange.index, paragraphRange.length, 'list', 'bullet');
                } else if (style.list === Constants.listType.numbered) {
                    quill.formatLine(paragraphRange.index, paragraphRange.length, 'list', 'ordered');
                }

                const blockFormat = getBlockFormatFromStyle(style);
                if (blockFormat) {
                    quill.formatLine(paragraphRange.index, paragraphRange.length, blockFormat.key, blockFormat.value);
                }
            }

            setSelectedStyleOptions([{ label: style.name, value: style.id }]);
        }
    }

    const handleHyperLinkSave = (value) => {
        let quill = getQuill(quillId);
        if (!quill) {
            return;
        }

        let selection = quill.getSelection();

        if (!selection && quillSelectionWhenClickHyperlink) {
            const quillTextContent = quill.getText(0, quill.getLength());
            if (quillTextContent === Constants.emptyQuillTextContent || isEmptyTextWithZeroSpaceStart(quillTextContent)) {
                quill.focus();
            } else {
                quill.setSelection(quillSelectionWhenClickHyperlink.index, quillSelectionWhenClickHyperlink.length);
            }
            selection = quill.getSelection();
        }

        if (!selection) {
            quill.setSelection(0, 0);
            selection = quill.getSelection();
        }

        quill.format('link', value);

        setQuillSelectionWhenClickHyperlink(null);
    }

    const handleHyperLinkRemove = () => {
        let quill = getQuill(quillId);
        if (!quill) {
            return;
        }

        let selection = quill.getSelection();

        if (!selection && quillSelectionWhenClickHyperlink) {
            const quillTextContent = quill.getText(0, quill.getLength());
            if (quillTextContent === Constants.emptyQuillTextContent || isEmptyTextWithZeroSpaceStart(quillTextContent)) {
                quill.focus();
            } else {
                quill.setSelection(quillSelectionWhenClickHyperlink.index, quillSelectionWhenClickHyperlink.length);
            }
            selection = quill.getSelection();
        }

        if (!selection) {
            quill.setSelection(0, 0);
            selection = quill.getSelection();
        }

        quill.format('link', false);

        setQuillSelectionWhenClickHyperlink(null);
    }


    return (
        <div className="flex flex-row gap-y-3 gap-x-3">
            <Select
                id={styleSelectId}
                styles={styleSelectStyles}
                isMulti={true}
                isClearable={false}
                isSearchable={false}
                isDisabled={disabled}
                value={selectedStyleOptions}
                className="w-72"
                onChange={handleStyleChange}
                onBlur={() => { setDisabled(true) }}
                options={styleOptions}
            />

            {/* <HyperLinkComponent
                disabled={disabled}
                state={toolbarStateRef.current.hyperLinkState}
                url={toolbarStateRef.current.hyperLink}
                onButtonClick={() => {
                    let quill = getQuill(quillId);
                    if (!quill) {
                        return false;
                    }

                    let selection = quill.getSelection();
                    if (!selection || selection.length === 0) {
                        toast.error('Please select some text to create hyperlink!', {
                            duration: Infinity,
                        });
                        return false;
                    }

                    setQuillSelectionWhenClickHyperlink(selection);
                    return true;
                }}
                onSave={(e) => handleHyperLinkSave(e)}
                onRemove={() => handleHyperLinkRemove()}
            /> */}
        </div>
    )
}