import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { FormTextInput } from "../../../components/Form/FormTextInput";
import { FormSelect } from "../../../components/Form/FormSelect";
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { useEffect, useState } from "react";
import * as Constants from "../../../utils/constants";
import { createStyle, getFonts, getStyle, updateStyle } from "../../../api/textlibrary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBold, faItalic, faUnderline, faAlignLeft, faAlignRight, faAlignJustify, faAlignCenter, faList, faListNumeric } from "@fortawesome/pro-solid-svg-icons"
import * as _ from 'lodash'
import ColorPicker from "../../../components/ColorPicker";
import toast from "react-hot-toast";
import { BlockPicker } from "react-color";

export default function StyleDesign() {
    const { id } = useParams();
    const navigate = useNavigate()
    const [fontOptions, setFontOptions] = useState([]);
    const [styleToEdit, setStyleToEdit] = useState({
        name: '',
        type: Constants.styleTypeOptions[1].value,
        fontId: '',
        fontSize: Constants.fontSizes[4].value,
        bold: false,
        italic: false,
        underline: false,
        alignment: Constants.alignmentType.left,
        list: Constants.listType.undefined,
        textColor: '#000000',
    });
    const [sampleStyle, setSampleStyle] = useState({
        fontFamily: '',
        fontSize: `${Constants.fontSizes[4].value}pt`,
        fontWeight: 'normal',
        fontStyle: 'normal',
        textDecoration: 'none',
        textAlign: 'none',
        color: '#000000',
    });
    const [disabled, setDisabled] = useState(false);

    const formatButtonClassNames = (state) => {
        if (state === Constants.formatButtonState.focused) {
            return "bg-gray-200 px-3 py-2 text-#536876 text-sm h-full border border-gray-300 rounded-md focus:outline-none";
        } else if (state === Constants.formatButtonState.normal) {
            return "bg-white px-3 py-2 text-#536876 text-sm h-full border border-gray-300 rounded-md focus:outline-none"
        } else if (state === Constants.formatButtonState.disabled) {
            return "bg-white px-3 py-2 text-#E8EDF2 text-sm h-full border border-gray-300 rounded-md focus:outline-none cursor-not-allowed "
        }
        return "";
    }

    const loadAction = useToastAction();

    const convertAlignment = (value) => {
        if (value === Constants.alignmentType.center) {
            return 'center';
        } else if (value === Constants.alignmentType.right) {
            return 'right';
        } else if (value === Constants.alignmentType.justify) {
            return 'justify';
        } else {
            return 'left';
        }
    }

    const loadData = async () => {
        loadAction.execute(async () => {
            let [fonts, style] = await Promise.all([
                getFonts(),
                id ? getStyle(id) : () => { },
            ]);

            if (fonts && fonts.length > 0) {
                fonts = _.orderBy(fonts, 'name', 'asc');
                setFontOptions(fonts.map(x => { return { name: x.name, value: x.id } }));

                if (id) {
                    setStyleToEdit(style);

                    const foundFont = fonts.find(x => x.id === style.fontId);
                    setSampleStyle(prevState => ({
                        ...prevState,
                        fontFamily: foundFont?.name,
                        fontSize: `${style.fontSize}pt`,
                        fontWeight: style.bold ? 'bold' : 'normal',
                        fontStyle: style.italic ? 'italic' : 'normal',
                        textDecoration: style.underline ? 'underline' : 'normal',
                        textAlign: convertAlignment(style.alignment),
                        color: style.textColor,
                    }));
                } else {
                    setStyleToEdit(prevState => ({
                        ...prevState,
                        fontId: fonts[0].id
                    }));
                    setSampleStyle(prevState => ({
                        ...prevState,
                        fontFamily: fonts[0].name,
                    }));
                }
            } else {
                toast.error('Font is not defined. Please create font.', {
                    duration: Infinity,
                });
                setDisabled(true);
                return;
            }
        }, "Failed to load data");
    }

    useEffect(() => {
        loadData();
    }, []);

    const SampleText = ({ values }) => {
        if (values.list === Constants.listType.unordered) {
            return (
                <ul className="list-disc" style={{ display: 'block', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: '0px', marginInlineEnd: '0px', paddingInlineStart: '40px' }}>
                    <li style={sampleStyle}>
                        The Eiffel Tower is one of the most recognizable structures in the world, located in Paris, France. Completed in 1889, it was initially criticized by some of France's leading artists and intellectuals for its design, but it has become a global cultural icon of France and one of the most visited buildings in the world. Its elegant structure and stunning panoramic views of Paris continue to captivate millions of visitors each year.
                    </li>
                    <li style={sampleStyle}>
                        The Pyramids of Giza are ancient structures located on the outskirts of Cairo, Egypt. Built as tombs for Egypt's pharaohs and consorts during the Old and Middle Kingdom periods, they continue to stand as a testament to the power and organization of the ancient Egyptians. The Great Pyramid of Giza is the oldest and largest pyramid and is one of the Seven Wonders of the Ancient World. Visiting the pyramids is like stepping back in time and witnessing the architectural marvel of ancient times.
                    </li>
                </ul>
            )
        } else if (values.list === Constants.listType.numbered) {
            return (
                <ol className="list-decimal" style={{ display: 'block', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: '0px', marginInlineEnd: '0px', paddingInlineStart: '40px' }}>
                    <li style={sampleStyle}>
                        The Eiffel Tower is one of the most recognizable structures in the world, located in Paris, France. Completed in 1889, it was initially criticized by some of France's leading artists and intellectuals for its design, but it has become a global cultural icon of France and one of the most visited buildings in the world. Its elegant structure and stunning panoramic views of Paris continue to captivate millions of visitors each year.
                    </li>
                    <li style={sampleStyle}>
                        The Pyramids of Giza are ancient structures located on the outskirts of Cairo, Egypt. Built as tombs for Egypt's pharaohs and consorts during the Old and Middle Kingdom periods, they continue to stand as a testament to the power and organization of the ancient Egyptians. The Great Pyramid of Giza is the oldest and largest pyramid and is one of the Seven Wonders of the Ancient World. Visiting the pyramids is like stepping back in time and witnessing the architectural marvel of ancient times.
                    </li>
                </ol>
            )
        }
        return (
            <p style={sampleStyle}>
                The Grand Canyon is one of the most famous natural wonders in the United States. Located in Arizona, it was carved over millions of years by the Colorado River. Its depth reaches over a mile, and its layered bands of red rock reveal a timeline of Earth's geologic history. It's an unparalleled beauty that attracts millions of tourists every year.
                The area offers numerous recreational activities, such as hiking, camping, river rafting, and even mule rides. Due to its vast size and variety of terrains, the Grand Canyon offers a unique and spectacular experience for each visitor. It's not just a national park, it's a destination that provides breathtaking views and unforgettable adventures. 
            </p>
        )
    }

    let StyleSchema = yup.object().shape({
        name: yup.string().required("Required"),
    });

    const handleSave = async (values) => {
        const clonedStyleToEdit = _.cloneDeep(styleToEdit);
        clonedStyleToEdit.name = values.name;
        clonedStyleToEdit.type = values.type;
        clonedStyleToEdit.fontId = values.fontId;
        clonedStyleToEdit.fontSize = values.fontSize;
        clonedStyleToEdit.bold = values.bold;
        clonedStyleToEdit.italic = values.italic;
        clonedStyleToEdit.underline = values.underline;
        clonedStyleToEdit.alignment = values.alignment;
        clonedStyleToEdit.list = values.list;
        clonedStyleToEdit.textColor = values.textColor;

        try {
            if (styleToEdit.id) {
                await updateStyle(clonedStyleToEdit);
            } else {
                await createStyle(clonedStyleToEdit);
            }
        } catch (err) {
            if (err.response?.status === 400 && err.response?.data?.Message.startsWith("TextLibraryErrors:")) {
                const message = err.response?.data?.Message.replace("TextLibraryErrors:", "");
                toast.error(message, {
                    duration: Infinity,
                });
            } else {
                toast.error("Failed to save font");
            }

            return;
        }

        navigate('/styles');
    }

    return (
        <>
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={styleToEdit}
                enableReinitialize={true}
                validationSchema={StyleSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    await handleSave(values);
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form className="" onSubmit={handleSubmit} >
                        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                            <div className="shadow-sm rounded-lg border border-gray-200 bg-white shadow rounded-lg">
                                <div className="space-y-8 divide-y divide-gray-200 p-8 pt-2">
                                    <div>
                                        {/* <div className="border-b py-3">
                                            <h1 className="text-3xl font-bold">{id ? "Edit Style" : "Create Style"}</h1>
                                        </div> */}

                                        <div className="sm:col-span-6 mt-6">
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">Style</h3>
                                        </div>

                                        <div className="mt-3 grid grid-cols-2 gap-6 ">
                                            <div className="">
                                                <FormTextInput
                                                    name="name"
                                                    label={"Name"}
                                                    value={values.name}
                                                    disabled={disabled}
                                                    error={errors.name}
                                                    touched={touched.name}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="">
                                                <FormSelect
                                                    name="type"
                                                    label={"Type"}
                                                    value={values.type}
                                                    disabled={disabled}
                                                    options={Constants.styleTypeOptions}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        if (e.target.value === '0') {
                                                            setFieldValue('alignment', Constants.alignmentType.undefined);
                                                            setFieldValue('list', Constants.listType.undefined);
                                                            setSampleStyle(prevState => ({
                                                                ...prevState,
                                                                textAlign: 'left',
                                                            }));
                                                        } else if (e.target.value === '1') {
                                                            setFieldValue('alignment', Constants.alignmentType.left);
                                                            setFieldValue('list', Constants.listType.undefined);
                                                            setSampleStyle(prevState => ({
                                                                ...prevState,
                                                                textAlign: 'left',
                                                            }));
                                                        } else if (e.target.value === '2') {
                                                            setFieldValue('alignment', Constants.alignmentType.undefined);
                                                            setFieldValue('list', Constants.listType.unordered);
                                                            setSampleStyle(prevState => ({
                                                                ...prevState,
                                                                textAlign: 'left',
                                                            }));
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-8 grid grid-cols-2 gap-6 ">             
                                            <hr className="sm:col-span-6" />

                                            <div className="sm:col-span-6 mt-4 -mb-3">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Formatting</h3>
                                            </div>

                                            <div className="sm:col-span-6">
                                                <div className="flex w-full gap-4">
                                                    <div className="sm:col-span-1">
                                                        <div className="flex gap-2">
                                                            <div className="">
                                                                <FormSelect
                                                                    name="fontId"
                                                                    label={""}
                                                                    value={values.fontId}
                                                                    disabled={disabled}
                                                                    className={"w-48"}
                                                                    options={fontOptions}
                                                                    onChange={(e) => {
                                                                        handleChange(e)
                                                                        const fontOption = fontOptions.find(x => x.value === e.target.value);
                                                                        if (fontOption) {
                                                                            setSampleStyle(prevState => ({
                                                                                ...prevState,
                                                                                fontFamily: fontOption?.name
                                                                            }));
                                                                        }
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="">
                                                                <FormSelect
                                                                    name="fontSize"
                                                                    label={""}
                                                                    className="w-18"
                                                                    value={values.fontSize}
                                                                    disabled={disabled}
                                                                    options={Constants.fontSizes}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        setSampleStyle(prevState => ({
                                                                            ...prevState,
                                                                            fontSize: `${e.target.value}pt`
                                                                        }))
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-1 sm:px-3">
                                                        <div className="flex items-center gap-x-2 h-full">
                                                            <button
                                                                type="button"
                                                                className={formatButtonClassNames(disabled ? Constants.formatButtonState.disabled : (values.bold ? Constants.formatButtonState.focused : Constants.formatButtonState.normal))}
                                                                disabled={disabled}
                                                                onClick={() => {
                                                                    setFieldValue('bold', !values.bold);
                                                                    setSampleStyle(prevState => ({
                                                                        ...prevState,
                                                                        fontWeight: values.bold ? 'normal' : 'bold'
                                                                    }))
                                                                }}
                                                            >
                                                                <FontAwesomeIcon className="" size={'sm'} icon={faBold} />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className={formatButtonClassNames(disabled ? Constants.formatButtonState.disabled : (values.italic ? Constants.formatButtonState.focused : Constants.formatButtonState.normal))}
                                                                disabled={disabled}
                                                                onClick={() => {
                                                                    setFieldValue('italic', !values.italic);
                                                                    setSampleStyle(prevState => ({
                                                                        ...prevState,
                                                                        fontStyle: values.italic ? 'normal' : 'italic'
                                                                    }))
                                                                }}
                                                            >
                                                                <FontAwesomeIcon className="" size={'sm'} icon={faItalic} />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className={formatButtonClassNames(disabled ? Constants.formatButtonState.disabled : (values.underline ? Constants.formatButtonState.focused : Constants.formatButtonState.normal))}
                                                                disabled={disabled}
                                                                onClick={() => {
                                                                    setFieldValue('underline', !values.underline);
                                                                    setSampleStyle(prevState => ({
                                                                        ...prevState,
                                                                        textDecoration: values.underline ? 'none' : 'underline'
                                                                    }))
                                                                }}
                                                            >
                                                                <FontAwesomeIcon className="" size={'sm'} icon={faUnderline} />
                                                            </button>

                                                            <ColorPicker
                                                                value={values.textColor}
                                                                disabled={disabled}
                                                                disableAlpha={true}
                                                                setValue={(color) => {
                                                                    setFieldValue('textColor', color)
                                                                    setSampleStyle(prevState => ({
                                                                        ...prevState,
                                                                        color: color
                                                                    }))
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                {/* </div>
                                            </div>

                                            <div className="sm:col-span-6">
                                                <div className="flex flex-row divide-x divide-gray-200"> */}
                                                {
                                                    values.alignment !== Constants.alignmentType.undefined && (
                                                    <div className="items-center space-x-2">
                                                        <button
                                                            type="button"
                                                            className={formatButtonClassNames(
                                                                (disabled || values.alignment === Constants.alignmentType.undefined) ? Constants.formatButtonState.disabled :
                                                                    values.alignment === Constants.alignmentType.left ? Constants.formatButtonState.focused : Constants.formatButtonState.normal)}
                                                            disabled={disabled || values.alignment === Constants.alignmentType.undefined}
                                                            onClick={() => {
                                                                setFieldValue('alignment', Constants.alignmentType.left);
                                                                setSampleStyle(prevState => ({
                                                                    ...prevState,
                                                                    textAlign: 'left'
                                                                }))
                                                            }}
                                                        >
                                                            <FontAwesomeIcon className="" size={'sm'} icon={faAlignLeft} />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={formatButtonClassNames(
                                                                (disabled || values.alignment === Constants.alignmentType.undefined) ? Constants.formatButtonState.disabled :
                                                                    values.alignment === Constants.alignmentType.center ? Constants.formatButtonState.focused : Constants.formatButtonState.normal)}
                                                            disabled={disabled || values.alignment === Constants.alignmentType.undefined}
                                                            onClick={() => {
                                                                setFieldValue('alignment', values.alignment === Constants.alignmentType.center ? Constants.alignmentType.left : Constants.alignmentType.center);
                                                                setSampleStyle(prevState => ({
                                                                    ...prevState,
                                                                    textAlign: values.alignment === Constants.alignmentType.center ? 'left' : 'center'
                                                                }))
                                                            }}
                                                        >
                                                            <FontAwesomeIcon className="" size={'sm'} icon={faAlignCenter} />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={formatButtonClassNames(
                                                                (disabled || values.alignment === Constants.alignmentType.undefined) ? Constants.formatButtonState.disabled :
                                                                    values.alignment === Constants.alignmentType.right ? Constants.formatButtonState.focused : Constants.formatButtonState.normal)}
                                                            disabled={disabled || values.alignment === Constants.alignmentType.undefined}
                                                            onClick={() => {
                                                                setFieldValue('alignment', values.alignment === Constants.alignmentType.right ? Constants.alignmentType.left : Constants.alignmentType.right);
                                                                setSampleStyle(prevState => ({
                                                                    ...prevState,
                                                                    textAlign: values.alignment === Constants.alignmentType.right ? 'left' : 'right'
                                                                }))
                                                            }}
                                                        >
                                                            <FontAwesomeIcon className="" size={'sm'} icon={faAlignRight} />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={formatButtonClassNames(
                                                                (disabled || values.alignment === Constants.alignmentType.undefined) ? Constants.formatButtonState.disabled :
                                                                    values.alignment === Constants.alignmentType.justify ? Constants.formatButtonState.focused : Constants.formatButtonState.normal)}
                                                            disabled={disabled || values.alignment === Constants.alignmentType.undefined}
                                                            onClick={() => {
                                                                setFieldValue('alignment', values.alignment === Constants.alignmentType.justify ? Constants.alignmentType.left : Constants.alignmentType.justify);
                                                                setSampleStyle(prevState => ({
                                                                    ...prevState,
                                                                    textAlign: values.alignment === Constants.alignmentType.justify ? 'left' : 'justify'
                                                                }))
                                                            }}
                                                        >
                                                            <FontAwesomeIcon className="" size={'sm'} icon={faAlignJustify} />
                                                        </button>
                                                    </div>
                                                    )}

                                                    {
                                                        values.list !== Constants.listType.undefined && (
                                                    
                                                    <div className="items-center space-x-2">
                                                        <button
                                                            type="button"
                                                            className={formatButtonClassNames(
                                                                (disabled || values.list === Constants.listType.undefined) ? Constants.formatButtonState.disabled :
                                                                    values.list === Constants.listType.unordered ? Constants.formatButtonState.focused : Constants.formatButtonState.normal)}
                                                            disabled={disabled || values.list === Constants.listType.undefined}
                                                            onClick={() => {
                                                                setFieldValue('list', Constants.listType.unordered);
                                                                setSampleStyle(prevState => ({
                                                                    ...prevState,
                                                                    textAlign: Constants.alignmentType.left,
                                                                }))
                                                            }}
                                                        >
                                                            <FontAwesomeIcon className="" size={'sm'} icon={faList} />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={formatButtonClassNames(
                                                                (disabled || values.list === Constants.listType.undefined) ? Constants.formatButtonState.disabled :
                                                                    values.list === Constants.listType.numbered ? Constants.formatButtonState.focused : Constants.formatButtonState.normal)}
                                                            disabled={disabled || values.list === Constants.listType.undefined}
                                                            onClick={() => {
                                                                setFieldValue('list', Constants.listType.numbered);
                                                                setSampleStyle(prevState => ({
                                                                    ...prevState,
                                                                    textAlign: Constants.alignmentType.left,
                                                                }))
                                                            }}
                                                        >
                                                            <FontAwesomeIcon className="" size={'sm'} icon={faListNumeric} />
                                                        </button>
                                                    </div>  
                                                    )}
                                                </div>
                                            </div>
                                            
                                            <div className="sm:col-span-6 select-none">
                                                <div className="text-sm">Sample Text</div>
                                                <div className="w-full px-3 py-3 shadow-sm border border-gray-300 rounded-md h-96 mt-1" >
                                                    <p className="text-gray-300" style={{ fontSize: '10pt' }}>
                                                        The Colosseum, also known as the Flavian Amphitheater, is an iconic landmark in Rome, Italy. Completed in 80 AD, it is the largest amphitheater ever built. The Colosseum was used for gladiatorial contests, public spectacles, and dramas based on Classical mythology. It now stands as a symbol of the grandeur of the Roman Empire and is one of the most popular tourist attractions in Rome.
                                                    </p>

                                                    <SampleText values={values} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="flex justify-end bg-gray-50 px-8 py-4">
                                    <Link to={`/styles`}
                                        className="justify-center inline-flex items-center px-5 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md shadow-md bg-white 
                                        hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400"
                                    >
                                        Cancel
                                    </Link>
                                    <button type="submit"
                                        disabled={disabled}
                                        className="ml-4 justify-center inline-flex items-center px-5 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-md text-white 
                                        bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}