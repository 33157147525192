import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/outline";
import { useState } from "react";
import * as Constants from "../utils/constants"
import * as _ from 'lodash'

export const useTableSort = (data, propertyName, direction) => {
    const [sortProperty, setSortProperty] = useState(propertyName);
    const [sortDirection, setSortDirection] = useState(direction);

    return {
        tableData: sortProperty
            ? _.orderBy(data, [sortProperty], [sortDirection])
            : data,
        orderBy: (propertyName) => {
            if (propertyName === sortProperty) {
                const newSortDirection = sortDirection === Constants.sortOrder.asc ? Constants.sortOrder.desc : Constants.sortOrder.asc;
                setSortDirection(newSortDirection);
                return newSortDirection;
            } else {
                setSortProperty(propertyName)
                setSortDirection(Constants.sortOrder.asc)
                return Constants.sortOrder.asc;
            }
        },
        getSortIcon: (propertyName) => {
            if (propertyName !== sortProperty) {
                return null;
            }
            return sortDirection === Constants.sortOrder.asc
                ? <ArrowSmUpIcon className="h-5 w-5 inline"></ArrowSmUpIcon>
                : <ArrowSmDownIcon className="h-5 w-5 inline"></ArrowSmDownIcon>
        },
    }
}