import { useState, useEffect } from 'react';

export default function TablePagination({ pagination, onPaginationChange, disabled }) {
    const [isInitialized, setIsInitilized] = useState(true);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        setIsInitilized(false);
    }, [])

    useEffect(() => {
        setPaginationPage(pagination.currentPage);
    }, [pagination.totalRecordsCount, pagination.currentPage])

    useEffect(() => {
        if (!isInitialized) {
            let newPagination = setPaginationPage(0);
            onPaginationChange(newPagination)
        }
    }, [pageSize])

    const setPaginationPage = (newPageIdx) => {
        return {
            ...pagination,
            currentPage: newPageIdx,
            pageSize: pageSize,
        };
    }
    
    const handlePreviousPageClick = () => {
        const newPagination = setPaginationPage((pagination.currentPage - 1));
        onPaginationChange(newPagination)
    }

    const handleNextPageClick = () => {
        const newPagination = setPaginationPage((pagination.currentPage + 1));
        onPaginationChange(newPagination)
    }

    return (
        <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
            aria-label="Pagination"
        >
            <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                    <span className="capitalize">showing </span>
                    <span className="font-medium">{pagination.currentPageStart}</span><span> to </span>
                    <span className="font-medium">{pagination.currentPageEnd}</span><span> of </span>
                    <span className="font-medium">{pagination.totalRecordsCount}</span><span> results</span>
                </p>
            </div>
            <div className="flex-1 flex justify-between sm:justify-end items-center">
                <div>
                    <p className="text-sm text-gray-700">
                        <span className="capitalize">Per page: </span>
                        <input
                            type="number"
                            min="1"
                            className="border text-sm mr-2 w-16 border-gray-300 rounded-md"
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                        />
                    </p>
                </div>
                <button
                    disabled={disabled || (pagination.currentPage === 0)}
                    onClick={handlePreviousPageClick}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    Previous
                </button>

                <button
                    disabled={disabled || (pagination.currentPage === pagination.lastPage)}
                    onClick={handleNextPageClick}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    Next
                </button>
            </div>
        </nav>
    )
}
