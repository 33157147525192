import * as Constants from "../utils/constants"

export const useQuill = () => {
    const getQuill = (elementId) => {
        let quill;
        const element = document.getElementById(elementId);
        if (element && element.__quill) {
            quill = element.__quill;
        }
        return quill;
    }

    const isEmptyTextWithZeroSpaceStart = (data) => {
        let result = true;
        if (data === undefined || data.length <= 1) {
            result = false;
        }
        if (data[data.length - 1] !== '\n') {
            result = false;
        }
        for (let i = 0; i < data.length - 1; i++) {
            if (data[i] !== '\uFEFF') {
                result = false;
            }
        }
        return result;
    }

    return {
        getQuillIdFromLanguageId : (languageId) => {
            return `quill${languageId}`;
        },
        getQuill: (elementId) => {
            return getQuill(elementId);
        },
        getQuillHtmlContent: (elementId) => {
            let quill = getQuill(elementId);

            let htmlContent = '';
            if (quill) {
                const textContent = quill.getText(0, quill.getLength());
                if (textContent === Constants.emptyQuillTextContent || isEmptyTextWithZeroSpaceStart(textContent)) {
                    htmlContent = '';
                } else {
                    htmlContent = quill.root.innerHTML.split('\uFEFF').join('');
                }
            }
            return htmlContent;
        },
        isEmptyTextWithZeroSpaceStart: (data) => {
            return isEmptyTextWithZeroSpaceStart(data);
        },
        getFormatFromStyle: (style) => {
            if (style.type === Constants.styleType.character) {
                return { key: `${Constants.jssCssPrefix}-${Constants.jssCssInline}`, value: style.id };
            } else {
                return { key: `${Constants.jssCssPrefix}-${Constants.jssCssBlock}`, value: style.id };
            }
        },
        getBlockFormatFromStyle: (style) => {
            if (style.type !== Constants.styleType.character) {
                return { key: `${Constants.jssCssPrefix}-${Constants.jssCssBlock}`, value: style.id };
            }
            return null;
        },
        getInlineFormatFromStyle: (style) => {
            return { key: `${Constants.jssCssPrefix}-${Constants.jssCssInline}`, value: style.id };
        },
        getCurrentParagraphRange: (elementId, selection) => {
            let quill = getQuill(elementId);
            if (!quill) {
                return null;
            }

            if (!selection) {
                selection = quill.getSelection();
                if (!selection) {
                    return null;
                }
            }

            let paragraphStartIndex = selection.index;
            let preText = quill.getText(0, selection.index);
            if (preText) {
                paragraphStartIndex = preText.lastIndexOf('\n');
                if (paragraphStartIndex >= 0) {
                    paragraphStartIndex = paragraphStartIndex + 1;
                } else {
                    paragraphStartIndex = 0;
                }
            }

            let paragraphEndIndex = selection.index + selection.length;
            let nextText = quill.getText(selection.index + selection.length, quill.getLength() - selection.index - selection.length);
            if (nextText) {
                paragraphEndIndex = nextText.indexOf('\n');
                if (paragraphEndIndex >= 0) {
                    paragraphEndIndex = paragraphEndIndex + selection.index + selection.length;
                    paragraphEndIndex = paragraphEndIndex >= 0 ? paragraphEndIndex : 0;
                } else {
                    paragraphEndIndex = quill.getLength() > 0 ? quill.getLength() - 1 : 0;
                }
            }

            if (paragraphEndIndex >= paragraphStartIndex) {
                return { index: paragraphStartIndex, length: (paragraphEndIndex - paragraphStartIndex) };
            }

            return null;
        },
        getLastCharacterIndexOfPreviousParagraph: (elementId) => {
            let quill = getQuill(elementId);
            if (!quill) {
                return null;
            }

            const selection = quill.getSelection();
            if (!selection) {
                return null;
            }

            let index = selection.index;
            let preText = quill.getText(0, selection.index);
            if (preText) {
                index = preText.lastIndexOf('\n');
                if (index > 0) {
                    index = index - 1;
                } else {
                    index = 0;
                }
            }

            return index;
        }
    }
}