import { useRef, useState } from "react";
import * as Constants from "../../utils/constants"
import _ from "lodash"
import RichTextEditorToolBar from './RichTextEditorToolBar';
import QuillEditor from './QuillEditor';
import CreateStyleCss from "./CreateStyleCss";
import { useQuill } from '../../hooks/useQuill';

export default function RichTextEditor({ languageId, reInitialized, stylesRef, fontsRef, text, setText, disabled, error }) {
    const [toolbarState, _setToolbarState] = useState({
        hyperLink: '',
        hyperLinkState: Constants.formatButtonState.disabled,
    });
    const toolbarStateRef = useRef(toolbarState);
    const setToolbarState = (data) => {
        toolbarStateRef.current = data;
        _setToolbarState(data);
    }
    const { getQuillIdFromLanguageId } = useQuill();

    const quillId = getQuillIdFromLanguageId(languageId);
    const styleSelectId = `styleSelect${languageId}`;

    const [richTextEditorToolBarDisabled, setRichTextEditorToolBarDisabled] = useState(true);

    return (
        <div>
            <div className="mb-1">
                <RichTextEditorToolBar
                    quillId={quillId}
                    styleSelectId={styleSelectId}
                    stylesRef={stylesRef}
                    fontsRef={fontsRef}
                    toolbarStateRef={toolbarStateRef}
                    setToolbarState={setToolbarState}
                    reInitialized={reInitialized}
                    disabled={richTextEditorToolBarDisabled}
                    setDisabled={setRichTextEditorToolBarDisabled}
                />

            </div>
            <div

                className={error ? "border border-brand-pink bg-white rounded-md px-3 py-3 overflow-y-auto" : "border border-gray-300 bg-white rounded-md px-3 py-3 overflow-y-auto"}
                style={{ fontSize: '10pt', height: '22rem' }}
            >
                {stylesRef.current && stylesRef.current.length > 0 && fontsRef.current && (
                    <CreateStyleCss stylesRef={stylesRef} fontsRef={fontsRef}>
                        <QuillEditor
                            id={quillId}
                            styleSelectId={styleSelectId}
                            reInitialized={reInitialized}
                            disabled={disabled}
                            toolbarStateRef={toolbarStateRef}
                            setToolbarState={setToolbarState}
                            stylesRef={stylesRef}
                            fontsRef={fontsRef}
                            text={text}
                            setText={setText}
                            setRichTextEditorToolBarDisabled={setRichTextEditorToolBarDisabled}
                        />
                    </CreateStyleCss>
                )}
            </div>
        </div>
    );
}