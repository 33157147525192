import { useClassNames } from "@metaforcelabs/metaforce-core"
import GenericModal from "../../../components/Modals/genericModal"
import { useEffect, useState } from "react";
import { createFont, updateFont } from "../../../api/textlibrary";
import toast from "react-hot-toast";

export default function EditFontDialog({ open, font, fonts, onConfirm }) {
    const { classNames } = useClassNames();
    const [error, setError] = useState();
    const [name, setName] = useState("");

    useEffect(() => {
        if (open) {
            setName(font.name);
            setError(undefined);
        }
    }, [open])

    const handleChange = (e) => {
        setName(e.target.value);
    }

    const handleConfirm = async (confirm) => {
        if (confirm) {
            if (!name) {
                setError("Required");
                return;
            } else if (fonts.find(f => f.name === name && f.id !== font.id)) {
                setError("Already exists");
                return;
            }

            try {
                if (font.id) {
                    await updateFont({
                        ...font,
                        name: name,
                    });
                } else {
                    await createFont({
                        name: name
                    });
                }
            } catch (err) {
                if (err.response?.status === 400 && err.response?.data?.Message?.startsWith("TextLibraryErrors:")) {
                    const message = err.response?.data?.Message.replace("TextLibraryErrors:", "");
                    toast.error(message);
                } else {
                    toast.error("Failed to save font");
                }
                return;
            }
        }

        onConfirm(confirm);
    }

    return (
        <GenericModal
            open={open}
            onClose={() => handleConfirm(false)}
            onConfirm={() => handleConfirm(true)}
            onCancel={() => handleConfirm(false)}
            confirmButtonText={"OK"}
            cancelButtonText={"Cancel"}
            showCancelButton={true}
        >
            <div className="">
                <h3 className="text-left text-lg font-medium py-2" >{font?.id ? 'Edit' : 'Create'} font</h3>
                <hr></hr>
                <div className="py-5">
                    <div className="mt-1 relative rounded-md w-full text-left">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 ">Name</label>
                        <input
                            id="name"
                            type={"text"}
                            name="name"
                            value={name}
                            className={classNames(`focus:ring-gray-400 focus:border-gray-400 block sm:text-sm rounded-md w-full`,
                                error ? "border-red-500" : "border-gray-300"
                            )}
                            onChange={handleChange}
                        />
                        {error ? (
                            <div className="text-red-500">{error}</div>
                        ) : null}
                    </div>
                </div>
            </div>
        </GenericModal>
    )
}