

export const adminProductId = '612359c5b73e79a3aa8b732d';

export const paginationPageSize = 10;
export const paginationMaxPages = 10;

export const textSortProperty = {
    name: 0,
    group: 1,
    language: 2,
    validFrom: 3,
    validTo: 4,
    createdTime: 5,
    createdByUserName: 6,
    modifiedTime: 7,
    userId: 8,
    identifier: 9,
    state: 10,
};

export const groupSortProperty = {
    name: 'name',
    createdTime: 'createdTime',
    createdByUserName: 'createdByUserName',
    modifiedTime: 'modifiedTime',
    modifiedByUserName: 'modifiedByUserName',
};

export const fontSortProperty = {
    name: 'name',
    createdDate: 'createdDate',
    createdByName: 'createdByName',
    updatedDate: 'updatedDate',
    updatedByName: 'updatedByName',
};

export const styleSortProperty = {
    name: 'name',
    createdDate: 'createdDate',
    createdByName: 'createdByName',
    updatedDate: 'updatedDate',
    updatedByName: 'updatedByName',
};

export const sortOrder = {
    asc: 'asc',
    desc: 'desc',
};

export const styleType = {
    character: 0,
    paragraph: 1,
    list: 2,
}

export const styleTypeOptions = [
    // { name: 'Character', value: 0 },
    { name: 'Paragraph', value: 1 },
    { name: 'List', value: 2 },
];

export const fontSizes = [
    { name: '6', value: 6 },
    { name: '7', value: 7 },
    { name: '8', value: 8 },
    { name: '9', value: 9 },
    { name: '10', value: 10 },
    { name: '11', value: 11 },
    { name: '12', value: 12 },
    { name: '13', value: 13 },
    { name: '14', value: 14 },
    { name: '15', value: 15 },
    { name: '16', value: 16 },
    { name: '17', value: 17 },
    { name: '18', value: 18 },
    { name: '19', value: 19 },
    { name: '20', value: 20 },
    { name: '24', value: 24 },
    { name: '28', value: 28 },
    { name: '32', value: 32 },
    { name: '36', value: 36 },
    { name: '40', value: 40 },
    { name: '44', value: 44 },
    { name: '48', value: 48 },
];

export const alignmentType = {
    left: 1,
    center: 2,
    right: 3,
    justify: 4,
    undefined: null,
}

export const listType = {
    unordered: 1,
    numbered: 2,
    undefined: null,
}

export const formatButtonState = {
    normal: 0,
    focused: 1,
    disabled: 2,
}

export const formatChangeType = {
    style: 0,
    fontFamily: 1,
    fontSize: 2,
    bold: 3,
    italic: 4,
    underline: 5,
    alignmentLeft: 6,
    alignmentCenter: 7,
    alignmentRight: 8,
    alignmentJustify: 9,
    unorderedList: 10,
    numberedList: 11,
    textColor: 12,
}

export const emptyQuillTextContent = '\n';
export const zeroSpaceQuillTextContent = '\uFEFF';
export const emptyQuillHtmlContent = '<p><br></p>';

export const textDesignCookies = 'tltd.session';

export const jssCssPrefix = 'ql';
export const jssCssBlock = 'block';
export const jssCssInline = 'inline';

export const templateTextState = {
    inactive: 1,
    active: 10,
}