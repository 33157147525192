import { useClassNames } from "@metaforcelabs/metaforce-core";

export const FormTextInput = ({
  name,
  label,
  value,
  disabled,
  onChange,
  placeholder = null,
  required = false,
  error,
}) => {
  const { classNames } = useClassNames();

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
        {required === true && <span className="text-red-400"> *</span>}
      </label>
      <div className="mt-1">
        <input
          type={"text"}
          name={name}
          value={value || ''}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          className={classNames(
            "block w-full shadow-sm sm:text-sm focus:ring-gray-400 focus:border-gray-400 border-gray-300 rounded-md disabled:opacity-50 disabled:cursor-not-allowed ",
            error 
              ? "placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 "
              : ""
          )}
        />
        {
          error &&
          <div className="mt-1 text-sm text-red-600 font-medium">
            {error}
          </div>
        }
      </div>
    </div>
  );
}