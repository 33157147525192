import { Popover } from '@headlessui/react'
import { useEffect } from 'react';
import { useState } from 'react';
import { PhotoshopPicker } from 'react-color';

export default function ColorPicker({ value, setValue, disabled, onButtonClick, disableAlpha }) {
    const [textColor, setTextColor] = useState(value);

    useEffect(() => {
        setTextColor(value);
    }, [value])

    return (
        <Popover className="relative h-full">
            <Popover.Button
                type="button"
                className="px-5 py-2 h-full shadow-sm sm:text-sm font-medium border border-gray-300 rounded-md focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                style={{ color: textColor }}
                disabled={disabled}
                onClick={onButtonClick}
            >
                Color
            </Popover.Button>

            <Popover.Panel className="absolute sm:right-0 z-50">
                {({ close }) => (
                    <PhotoshopPicker
                        className='mt-1'
                        color={textColor}
                        disableAlpha={disableAlpha}
                        onChangeComplete={(color) => {
                            setTextColor(color.hex)
                        }}
                        onAccept={() => {
                            close();
                            setValue(textColor);
                        }}
                        onCancel={() => {
                            close();
                            setTextColor(value);
                            setValue(value);
                        }}
                    />
                )}
            </Popover.Panel>
        </Popover>
    )
}