import { useContext, useState } from "react";
import { useSelector } from "react-redux"
import { useTableSort } from "../../../hooks/useTableSort";
import * as _ from 'lodash'
import { TextLibraryContext } from "../../../contexts";
import * as Constants from "../../../utils/constants";
import DeleteDialog from "../DeleteDialog";
import { deleteText, duplicateText, updateText } from "../../../api/textlibrary";
import TablePagination from "../../../components/TablePagination";
import RenameDialog from "./renameDialog";
import { stringToLocaleDateTimeString } from "@metaforcelabs/metaforce-core";
import { NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import { MenuListCtxMenu } from "./menuListCtxMenu";

export default function TextTable() {
    const { searchResult, groupOptions, languageOptions, onPaginationChange, isSearching, onTableSort, debouncedSearchTexts, handleExportTexts } = useContext(TextLibraryContext);
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [templateTextToRename, setTemplateTextToRename] = useState();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [templateTextToDelete, setTemplateTextToDelete] = useState();
    const searchRequest = useSelector((state) => state.texts.searchRequest);
    const pagination = useSelector((state) => state.texts.pagination);

    const tableSort = useTableSort(searchResult?.texts, searchRequest?.sortProperty, searchRequest?.sortOrder);

    const handleGroup = (id) => {
        const group = groupOptions.find(x => x.value === id);
        return group ? group.name : '';
    }

    const handleLanguage = (templateTextValues) => {
        const languages = [];
        templateTextValues.forEach(textValue => {
            const language = languageOptions.find(x => x.value === textValue.languageId);
            if (language) {
                languages.push(language.name);
            }
        })

        return (
            <ul>
                {languages.map((language) => (<li key={language}>{language}</li>))}
            </ul>
        )
    }

    const handleDuplicate = async (templeteTextToSelect) => {
        if (templeteTextToSelect) {
            try {
                await duplicateText(templeteTextToSelect.id);
            } catch (err) {
                if (err.response?.status === 400 && err.response?.data?.Message?.startsWith("TextLibraryErrors:")) {
                    const message = err.response?.data?.Message.replace("TextLibraryErrors:", "");
                    toast.error(message);
                } else {
                    toast.error("Failed to duplicate text");
                }
                return;
            }
            debouncedSearchTexts();
        }
    }

    const handleDeactivate = async (templeteTextToSelect) => {
        if (templeteTextToSelect) {
            await updateText({
                ...templeteTextToSelect,
                state: Constants.templateTextState.inactive
            })
            debouncedSearchTexts();
        }
    }

    const handleActivate = async (templeteTextToSelect) => {
        if (templeteTextToSelect) {
            await updateText({
                ...templeteTextToSelect,
                state: Constants.templateTextState.active
            })
            debouncedSearchTexts();
        }
    }

    const handleExport = async (templeteTextToSelect) => {
        if (templeteTextToSelect)
            await handleExportTexts(templeteTextToSelect);
    }

    const handleRename = async (confirm) => {
        if (confirm) {
            debouncedSearchTexts();
        }
        setRenameDialogOpen(false);
        setTemplateTextToRename(null);
    }

    const handleDelete = async (confirm) => {
        if (confirm && templateTextToDelete) {
            await deleteText(templateTextToDelete);
            debouncedSearchTexts();
        }
        setDeleteDialogOpen(false);
        setTemplateTextToDelete(null);
    }

    return (
        <>
            <div className='w-full h-full mt-5'>
                <div className="-my-2 sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
                        <div className="shadow-sm border border-gray-200 sm:rounded-lg overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200 ">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="bg-gray-50">
                                            <button
                                                className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                                disabled={isSearching}
                                                onClick={() => {
                                                    const newSortDirection = tableSort.orderBy(Constants.textSortProperty.identifier)
                                                    onTableSort({
                                                        sortProperty: Constants.textSortProperty.identifier,
                                                        sortOrder: newSortDirection,
                                                    });
                                                }}
                                            >
                                                Identifier {tableSort.getSortIcon(Constants.textSortProperty.identifier)}
                                            </button>
                                        </th>
                                        <th scope="col" className="bg-gray-50">
                                            <button
                                                className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                                disabled={isSearching}
                                                onClick={() => {
                                                    const newSortDirection = tableSort.orderBy(Constants.textSortProperty.name)
                                                    onTableSort({
                                                        sortProperty: Constants.textSortProperty.name
                                                        ,
                                                        sortOrder: newSortDirection,
                                                    });
                                                }}
                                            >
                                                Name {tableSort.getSortIcon(Constants.textSortProperty.name)}
                                            </button>
                                        </th>
                                        <th scope="col" className="bg-gray-50">
                                            <button
                                                className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                                disabled={isSearching}
                                                onClick={() => {
                                                    const newSortDirection = tableSort.orderBy(Constants.textSortProperty.state)
                                                    onTableSort({
                                                        sortProperty: Constants.textSortProperty.state
                                                        ,
                                                        sortOrder: newSortDirection,
                                                    });
                                                }}
                                            >
                                                State {tableSort.getSortIcon(Constants.textSortProperty.state)}
                                            </button>
                                        </th>
                                        <th scope="col" className="bg-gray-50">
                                            <button
                                                className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                                disabled={isSearching}
                                                onClick={() => {
                                                    const newSortDirection = tableSort.orderBy(Constants.textSortProperty.group)
                                                    onTableSort({
                                                        sortProperty: Constants.textSortProperty.group
                                                        ,
                                                        sortOrder: newSortDirection,
                                                    });
                                                }}
                                            >
                                                Group {tableSort.getSortIcon(Constants.textSortProperty.group)}
                                            </button>
                                        </th>
                                        <th scope="col" className="bg-gray-50">
                                            <button
                                                className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                                disabled={isSearching}
                                                onClick={() => {
                                                    const newSortDirection = tableSort.orderBy(Constants.textSortProperty.language)
                                                    onTableSort({
                                                        sortProperty: Constants.textSortProperty.language,
                                                        sortOrder: newSortDirection,
                                                    });
                                                }}
                                            >
                                                Language {tableSort.getSortIcon(Constants.textSortProperty.language)}
                                            </button>
                                        </th>
                                        {/* <th scope="col" className="bg-gray-50">
                                        <button
                                            className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                            disabled={isSearching}
                                            onClick={() => {
                                                const newSortDirection = tableSort.orderBy(Constants.textSortProperty.validFrom)
                                                onTableSort({
                                                    sortProperty: Constants.textSortProperty.validFrom,
                                                    sortOrder: newSortDirection,
                                                });
                                            }}
                                        >
                                            Valid from {tableSort.getSortIcon(Constants.textSortProperty.validFrom)}
                                        </button>
                                    </th>
                                    <th scope="col" className="bg-gray-50">
                                        <button
                                            className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                            disabled={isSearching}
                                            onClick={() => {
                                                const newSortDirection = tableSort.orderBy(Constants.textSortProperty.validTo)
                                                onTableSort({
                                                    sortProperty: Constants.textSortProperty.validTo,
                                                    sortOrder: newSortDirection,
                                                });
                                            }}
                                        >
                                            Valid to {tableSort.getSortIcon(Constants.textSortProperty.validTo)}
                                        </button>
                                    </th> */}
                                        {/* <th scope="col" className="bg-gray-50">
                                            <button
                                                className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                                disabled={isSearching}
                                                onClick={() => {
                                                    const newSortDirection = tableSort.orderBy(Constants.textSortProperty.createdTime)
                                                    onTableSort({
                                                        sortProperty: Constants.textSortProperty.createdTime,
                                                        sortOrder: newSortDirection,
                                                    });
                                                }}
                                            >
                                                Created {tableSort.getSortIcon(Constants.textSortProperty.createdTime)}
                                            </button>
                                        </th> */}
                                        {/* <th scope="col" className="bg-gray-50">
                                        <button
                                            className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                            disabled={isSearching}
                                            onClick={() => {
                                                const newSortDirection = tableSort.orderBy(Constants.textSortProperty.createdByUserName)
                                                onTableSort({
                                                    sortProperty: Constants.textSortProperty.createdByUserName,
                                                    sortOrder: newSortDirection,
                                                });
                                            }}
                                        >
                                            Created by {tableSort.getSortIcon(Constants.textSortProperty.createdByUserName)}
                                        </button>
                                    </th> */}
                                        <th scope="col" className="bg-gray-50">
                                            <button
                                                className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                                disabled={isSearching}
                                                onClick={() => {
                                                    const newSortDirection = tableSort.orderBy(Constants.textSortProperty.modifiedTime)
                                                    onTableSort({
                                                        sortProperty: Constants.textSortProperty.modifiedTime,
                                                        sortOrder: newSortDirection,
                                                    });
                                                }}
                                            >
                                                Updated {tableSort.getSortIcon(Constants.textSortProperty.modifiedTime)}
                                            </button>
                                        </th>
                                        {/* <th scope="col" className="bg-gray-50">
                                        <button
                                            className="inline-flex items-center w-full px-3 xl:px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                            disabled={isSearching}
                                            onClick={() => {
                                                const newSortDirection = tableSort.orderBy(Constants.textSortProperty.userId)
                                                onTableSort({
                                                    sortProperty: Constants.textSortProperty.userId,
                                                    sortOrder: newSortDirection,
                                                });
                                            }}
                                        >
                                            Updated by {tableSort.getSortIcon(Constants.textSortProperty.userId)}
                                        </button>
                                    </th> */}
                                        <th scope="col" className="bg-gray-50 px-6 py-4 relative">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {searchResult?.texts?.map((templateText, index) => (
                                        <tr key={templateText.id} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                                            <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{templateText.identifier}</td>
                                            <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-indigo-600 overflow-ellipsis overflow-hidden max-w-120 lg:max-w-150 xl:max-w-250">
                                                <NavLink className={"cursor-pointer hover:underline"}
                                                    to={`/text/${templateText.id}`}>
                                                    {templateText.name}
                                                </NavLink>
                                            </td>
                                            <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">
                                                {templateText.state === Constants.templateTextState.active ? 'Active' : 'Inactive'}
                                            </td>
                                            <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 overflow-ellipsis overflow-hidden max-w-96 lg:max-w-120 xl:max-w-150">
                                                {handleGroup(templateText.groupId)}
                                            </td>
                                            <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{handleLanguage(templateText.values)}</td>
                                            {/* <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{stringToLocaleDateTimeString(templateText.validFrom)}</td> */}
                                            {/* <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{stringToLocaleDateTimeString(templateText.validTo)}</td> */}
                                            {/* <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{stringToLocaleDateTimeString(templateText.createdTime)}</td> */}
                                            {/* <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{templateText.createdByUserName}</td> */}
                                            <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{stringToLocaleDateTimeString(templateText.modifiedTime)}</td>
                                            {/* <td className="px-3 xl:px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">{templateText.modifiedByUserName}</td> */}
                                            <td className="whitespace-nowrap text-right text-sm font-medium ">
                                                <div className="absolute -mt-2">
                                                    <MenuListCtxMenu
                                                        selectedTempleteText={templateText}
                                                        onDuplicate={handleDuplicate}
                                                        onActivate={handleActivate}
                                                        onDeactivate={handleDeactivate}
                                                        onExport={handleExport}
                                                        onRename={(templeteTextToSelect) => {
                                                            setRenameDialogOpen(true);
                                                            setTemplateTextToRename(_.cloneDeep(templeteTextToSelect));
                                                        }}
                                                        onDelete={(templeteTextToSelect) => {
                                                            setDeleteDialogOpen(true);
                                                            setTemplateTextToDelete(_.cloneDeep(templeteTextToSelect))
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {searchResult && searchResult.count > 0 && (
                                <TablePagination
                                    pagination={pagination}
                                    disabled={isSearching}
                                    onPaginationChange={(e) => onPaginationChange(e)}
                                />
                            )}
                        </div>

                    </div>
                </div>
            </div>
            {templateTextToDelete &&
                <DeleteDialog
                    title={<>Are you sure you want to delete text <span className="text-indigo-700">{templateTextToDelete.name}</span>?</>}
                    open={deleteDialogOpen}
                    onConfirm={handleDelete}
                />
            }
            {templateTextToRename &&
                <RenameDialog
                    open={renameDialogOpen}
                    setOpen={setRenameDialogOpen}
                    templateText={templateTextToRename}
                    onConfirm={handleRename}
                />
            }
        </>
    )
}