import { useClassNames } from "@metaforcelabs/metaforce-core"

export const FormSelect = ({
  name,
  label,
  value,
  disabled,
  onChange,
  placeholder = null,
  required = false,
  options,
  error,
  className
}) => {
  const { classNames } = useClassNames();

  return (
    <div className={classNames(className, "")}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
        {required === true && <span className="text-red-400"> *</span>}
      </label>
      <div className="mt-1">
        <select
          name={name}
          value={value}
          disabled={disabled}
          className={classNames(
            "block w-full shadow-sm sm:text-sm focus:ring-gray-400 focus:border-gray-400 border-gray-300 rounded-md disabled:opacity-50 disabled:cursor-not-allowed ",
            error 
              ? "placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 "
              : ""          )}
          onChange={onChange}
        >
          {
            options.map((option, index) => {
              return (<option key={`${option.name}${option.value}`} value={option.value}>{option.name}</option>)
            })
          }
        </select>
        {
          error && 
          <div className="mt-1 text-sm text-red-600 font-medium">
            {error}
          </div>
        }
      </div>
    </div>
  )
}